/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiDashboardGetAdminDashboardPost } from '../fn/dashboard/api-dashboard-get-admin-dashboard-post';
import { ApiDashboardGetAdminDashboardPost$Params } from '../fn/dashboard/api-dashboard-get-admin-dashboard-post';
import { apiDashboardGetAdminDashboardPost$Plain } from '../fn/dashboard/api-dashboard-get-admin-dashboard-post-plain';
import { ApiDashboardGetAdminDashboardPost$Plain$Params } from '../fn/dashboard/api-dashboard-get-admin-dashboard-post-plain';
import { GetAdminDashboardQueryResult } from '../models/get-admin-dashboard-query-result';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiDashboardGetAdminDashboardPost()` */
  static readonly ApiDashboardGetAdminDashboardPostPath = '/api/Dashboard/GetAdminDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardGetAdminDashboardPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDashboardGetAdminDashboardPost$Plain$Response(params?: ApiDashboardGetAdminDashboardPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAdminDashboardQueryResult>> {
    return apiDashboardGetAdminDashboardPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardGetAdminDashboardPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDashboardGetAdminDashboardPost$Plain(params?: ApiDashboardGetAdminDashboardPost$Plain$Params, context?: HttpContext): Observable<GetAdminDashboardQueryResult> {
    return this.apiDashboardGetAdminDashboardPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAdminDashboardQueryResult>): GetAdminDashboardQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDashboardGetAdminDashboardPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDashboardGetAdminDashboardPost$Response(params?: ApiDashboardGetAdminDashboardPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAdminDashboardQueryResult>> {
    return apiDashboardGetAdminDashboardPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDashboardGetAdminDashboardPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDashboardGetAdminDashboardPost(params?: ApiDashboardGetAdminDashboardPost$Params, context?: HttpContext): Observable<GetAdminDashboardQueryResult> {
    return this.apiDashboardGetAdminDashboardPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAdminDashboardQueryResult>): GetAdminDashboardQueryResult => r.body)
    );
  }

}
