import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROLES, SWAL } from 'src/app/app-const';
import { PlatformUpdate } from 'src/app/core/backend/models';
import { FeedbacksService, PublicDataService } from 'src/app/core/backend/services';
import { SIDEBAR_TYPE } from "../layouts.model";

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})

/**
 * Vertical component
 */
export class VerticalComponent implements OnInit, AfterViewInit {
  roles = ROLES;
  isCondensed = false;
  sidebartype = '';
  feedbackMessage = '';

  constructor(
    private router: Router,
    private publicService: PublicDataService,
    private feedbackService: FeedbacksService,
    private modalService: NgbModal
  ) {
    this.router.events.forEach((event: any) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
        // close all open modals
        modalService?.dismissAll();
      }
    });
  }

  ngOnInit() {
    this.sidebartype = SIDEBAR_TYPE;
    this.changeSidebar(this.sidebartype);
    document.body.setAttribute('data-layout', 'vertical');

  }

  @ViewChild('feedbackTemp') feedbackTemp: TemplateRef<any>;
  openFeedbackTemp() {
    this.modalService.open(this.feedbackTemp, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }

  ngAfterViewInit(): void {
    this.getSystemUpdates();
    // I want to show model twice daily
    const feedBackPoped = localStorage.getItem('feedbackModal');
    if (!feedBackPoped ||
      (
        feedBackPoped !== "SENT" &&
        new Date().getHours() !== new Date(feedBackPoped!).getHours()
      )) {
      this.openFeedbackTemp();
      localStorage.setItem('feedbackModal', new Date().toISOString());
    }

  }
  sendingFeedback = false;
  sendFeedback() {
    if (this.feedbackMessage) {
      this.sendingFeedback = true
      this.feedbackService.apiFeedbacksSendFeedbackPost({
        body: { message: this.feedbackMessage }
      }).subscribe({
        next: (res) => {
          this.feedbackMessage = '';
          this.modalService.dismissAll();
          SWAL('success', "😘تم ارسال التعليق بنجاح، نشكرك بشدة ");
          this.sendingFeedback = false;
          localStorage.setItem('feedbackModal', "SENT");

        },
        error: (res) => {
          SWAL('error', res?.errorCode);
          this.sendingFeedback = false;

        }
      })
    }
  }

  newFeatsCount = 0;
  localPlatformUpdates: string[] = [];
  platformUpdates: PlatformUpdate[] = [];
  getSystemUpdates() {
    if (localStorage.getItem('localPlatformUpdates')) {
      this.localPlatformUpdates = JSON.parse(localStorage.getItem('localPlatformUpdates')!);
    }
    this.publicService.apiPublicDataGetPlatformUpdatesGet().subscribe({
      next: (res) => {
        this.platformUpdates = res;
        if (!this.platformUpdates.length) {
          localStorage.removeItem('localPlatformUpdates');
        }
        if (this.platformUpdates?.length && this.platformUpdates.some(x => !this.localPlatformUpdates.includes(x.id!))) {
          this.openUpdatesTemp();
          this.newFeatsCount = this.platformUpdates.filter(x => !this.localPlatformUpdates.includes(x.id!))?.length

        }
      }
    })
  }

  @ViewChild('updatesTemp') updatesTemp: TemplateRef<any>;
  openUpdatesTemp() {
    localStorage.setItem('localPlatformUpdates', JSON.stringify(this.platformUpdates.map(x => x.id!)));
    this.newFeatsCount = 0;
    this.modalService.open(this.updatesTemp, { ariaLabelledBy: 'modal-basic-title', centered: true })
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }


  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  changeSidebar(value: any) {
    switch (value) {
      case "light":
        document.body.setAttribute('data-sidebar', 'light');
        document.body.setAttribute('data-topbar', 'light');
        document.body.removeAttribute('data-sidebar-size');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "compact":
        document.body.setAttribute('data-sidebar-size', 'small');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "dark":
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-sidebar-size');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "icon":
        document.body.classList.add('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-layout-size');
        document.body.setAttribute('data-keep-enlarged', "true");
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "colored":
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'colored');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        document.body.removeAttribute('data-sidebar-size');
        break;
      default:
        document.body.setAttribute('data-sidebar', 'dark');
        break;
    }
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }


}
