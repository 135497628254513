/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCourseAssignCourseToTeacherPost } from '../fn/course/api-course-assign-course-to-teacher-post';
import { ApiCourseAssignCourseToTeacherPost$Params } from '../fn/course/api-course-assign-course-to-teacher-post';
import { apiCourseAssignCourseToTeacherPost$Plain } from '../fn/course/api-course-assign-course-to-teacher-post-plain';
import { ApiCourseAssignCourseToTeacherPost$Plain$Params } from '../fn/course/api-course-assign-course-to-teacher-post-plain';
import { apiCourseCreateCoursePost } from '../fn/course/api-course-create-course-post';
import { ApiCourseCreateCoursePost$Params } from '../fn/course/api-course-create-course-post';
import { apiCourseCreateCoursePost$Plain } from '../fn/course/api-course-create-course-post-plain';
import { ApiCourseCreateCoursePost$Plain$Params } from '../fn/course/api-course-create-course-post-plain';
import { apiCourseDeleteCourseDelete } from '../fn/course/api-course-delete-course-delete';
import { ApiCourseDeleteCourseDelete$Params } from '../fn/course/api-course-delete-course-delete';
import { apiCourseDeleteCourseDelete$Plain } from '../fn/course/api-course-delete-course-delete-plain';
import { ApiCourseDeleteCourseDelete$Plain$Params } from '../fn/course/api-course-delete-course-delete-plain';
import { apiCourseGetCoursePost } from '../fn/course/api-course-get-course-post';
import { ApiCourseGetCoursePost$Params } from '../fn/course/api-course-get-course-post';
import { apiCourseGetCoursePost$Plain } from '../fn/course/api-course-get-course-post-plain';
import { ApiCourseGetCoursePost$Plain$Params } from '../fn/course/api-course-get-course-post-plain';
import { apiCourseGetCoursesPost } from '../fn/course/api-course-get-courses-post';
import { ApiCourseGetCoursesPost$Params } from '../fn/course/api-course-get-courses-post';
import { apiCourseGetCoursesPost$Plain } from '../fn/course/api-course-get-courses-post-plain';
import { ApiCourseGetCoursesPost$Plain$Params } from '../fn/course/api-course-get-courses-post-plain';
import { apiCourseGetCourseStudentsPost } from '../fn/course/api-course-get-course-students-post';
import { ApiCourseGetCourseStudentsPost$Params } from '../fn/course/api-course-get-course-students-post';
import { apiCourseGetCourseStudentsPost$Plain } from '../fn/course/api-course-get-course-students-post-plain';
import { ApiCourseGetCourseStudentsPost$Plain$Params } from '../fn/course/api-course-get-course-students-post-plain';
import { apiCourseGetCourseTeacherPost } from '../fn/course/api-course-get-course-teacher-post';
import { ApiCourseGetCourseTeacherPost$Params } from '../fn/course/api-course-get-course-teacher-post';
import { apiCourseGetCourseTeacherPost$Plain } from '../fn/course/api-course-get-course-teacher-post-plain';
import { ApiCourseGetCourseTeacherPost$Plain$Params } from '../fn/course/api-course-get-course-teacher-post-plain';
import { apiCourseUpdateCoursePost } from '../fn/course/api-course-update-course-post';
import { ApiCourseUpdateCoursePost$Params } from '../fn/course/api-course-update-course-post';
import { apiCourseUpdateCoursePost$Plain } from '../fn/course/api-course-update-course-post-plain';
import { ApiCourseUpdateCoursePost$Plain$Params } from '../fn/course/api-course-update-course-post-plain';
import { AssignCourseToTeacherCommandResult } from '../models/assign-course-to-teacher-command-result';
import { CreateCourseCommandResult } from '../models/create-course-command-result';
import { DeleteCourseCommandResult } from '../models/delete-course-command-result';
import { GetCourseQueryResult } from '../models/get-course-query-result';
import { GetCoursesQueryResult } from '../models/get-courses-query-result';
import { GetCourseTeacherQueryResult } from '../models/get-course-teacher-query-result';
import { GetCourseTeacherStudentQueryResult } from '../models/get-course-teacher-student-query-result';
import { UpdateCourseCommandResult } from '../models/update-course-command-result';

@Injectable({ providedIn: 'root' })
export class CourseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCourseGetCoursePost()` */
  static readonly ApiCourseGetCoursePostPath = '/api/Course/GetCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCoursePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursePost$Plain$Response(params?: ApiCourseGetCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseQueryResult>> {
    return apiCourseGetCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCoursePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursePost$Plain(params?: ApiCourseGetCoursePost$Plain$Params, context?: HttpContext): Observable<GetCourseQueryResult> {
    return this.apiCourseGetCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseQueryResult>): GetCourseQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCoursePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursePost$Response(params?: ApiCourseGetCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseQueryResult>> {
    return apiCourseGetCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCoursePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursePost(params?: ApiCourseGetCoursePost$Params, context?: HttpContext): Observable<GetCourseQueryResult> {
    return this.apiCourseGetCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseQueryResult>): GetCourseQueryResult => r.body)
    );
  }

  /** Path part for operation `apiCourseGetCoursesPost()` */
  static readonly ApiCourseGetCoursesPostPath = '/api/Course/GetCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCoursesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursesPost$Plain$Response(params?: ApiCourseGetCoursesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCoursesQueryResult>> {
    return apiCourseGetCoursesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCoursesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursesPost$Plain(params?: ApiCourseGetCoursesPost$Plain$Params, context?: HttpContext): Observable<GetCoursesQueryResult> {
    return this.apiCourseGetCoursesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCoursesQueryResult>): GetCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCoursesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursesPost$Response(params?: ApiCourseGetCoursesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCoursesQueryResult>> {
    return apiCourseGetCoursesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCoursesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCoursesPost(params?: ApiCourseGetCoursesPost$Params, context?: HttpContext): Observable<GetCoursesQueryResult> {
    return this.apiCourseGetCoursesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCoursesQueryResult>): GetCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiCourseGetCourseStudentsPost()` */
  static readonly ApiCourseGetCourseStudentsPostPath = '/api/Course/GetCourseStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCourseStudentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseStudentsPost$Plain$Response(params?: ApiCourseGetCourseStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherStudentQueryResult>> {
    return apiCourseGetCourseStudentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCourseStudentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseStudentsPost$Plain(params?: ApiCourseGetCourseStudentsPost$Plain$Params, context?: HttpContext): Observable<GetCourseTeacherStudentQueryResult> {
    return this.apiCourseGetCourseStudentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherStudentQueryResult>): GetCourseTeacherStudentQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCourseStudentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseStudentsPost$Response(params?: ApiCourseGetCourseStudentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherStudentQueryResult>> {
    return apiCourseGetCourseStudentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCourseStudentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseStudentsPost(params?: ApiCourseGetCourseStudentsPost$Params, context?: HttpContext): Observable<GetCourseTeacherStudentQueryResult> {
    return this.apiCourseGetCourseStudentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherStudentQueryResult>): GetCourseTeacherStudentQueryResult => r.body)
    );
  }

  /** Path part for operation `apiCourseGetCourseTeacherPost()` */
  static readonly ApiCourseGetCourseTeacherPostPath = '/api/Course/GetCourseTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCourseTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseTeacherPost$Plain$Response(params?: ApiCourseGetCourseTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherQueryResult>> {
    return apiCourseGetCourseTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCourseTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseTeacherPost$Plain(params?: ApiCourseGetCourseTeacherPost$Plain$Params, context?: HttpContext): Observable<GetCourseTeacherQueryResult> {
    return this.apiCourseGetCourseTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherQueryResult>): GetCourseTeacherQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseGetCourseTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseTeacherPost$Response(params?: ApiCourseGetCourseTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherQueryResult>> {
    return apiCourseGetCourseTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseGetCourseTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseGetCourseTeacherPost(params?: ApiCourseGetCourseTeacherPost$Params, context?: HttpContext): Observable<GetCourseTeacherQueryResult> {
    return this.apiCourseGetCourseTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherQueryResult>): GetCourseTeacherQueryResult => r.body)
    );
  }

  /** Path part for operation `apiCourseCreateCoursePost()` */
  static readonly ApiCourseCreateCoursePostPath = '/api/Course/CreateCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseCreateCoursePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseCreateCoursePost$Plain$Response(params?: ApiCourseCreateCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCourseCommandResult>> {
    return apiCourseCreateCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseCreateCoursePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseCreateCoursePost$Plain(params?: ApiCourseCreateCoursePost$Plain$Params, context?: HttpContext): Observable<CreateCourseCommandResult> {
    return this.apiCourseCreateCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCourseCommandResult>): CreateCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseCreateCoursePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseCreateCoursePost$Response(params?: ApiCourseCreateCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCourseCommandResult>> {
    return apiCourseCreateCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseCreateCoursePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseCreateCoursePost(params?: ApiCourseCreateCoursePost$Params, context?: HttpContext): Observable<CreateCourseCommandResult> {
    return this.apiCourseCreateCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCourseCommandResult>): CreateCourseCommandResult => r.body)
    );
  }

  /** Path part for operation `apiCourseUpdateCoursePost()` */
  static readonly ApiCourseUpdateCoursePostPath = '/api/Course/UpdateCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseUpdateCoursePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseUpdateCoursePost$Plain$Response(params?: ApiCourseUpdateCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCourseCommandResult>> {
    return apiCourseUpdateCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseUpdateCoursePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseUpdateCoursePost$Plain(params?: ApiCourseUpdateCoursePost$Plain$Params, context?: HttpContext): Observable<UpdateCourseCommandResult> {
    return this.apiCourseUpdateCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCourseCommandResult>): UpdateCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseUpdateCoursePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseUpdateCoursePost$Response(params?: ApiCourseUpdateCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCourseCommandResult>> {
    return apiCourseUpdateCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseUpdateCoursePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseUpdateCoursePost(params?: ApiCourseUpdateCoursePost$Params, context?: HttpContext): Observable<UpdateCourseCommandResult> {
    return this.apiCourseUpdateCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCourseCommandResult>): UpdateCourseCommandResult => r.body)
    );
  }

  /** Path part for operation `apiCourseAssignCourseToTeacherPost()` */
  static readonly ApiCourseAssignCourseToTeacherPostPath = '/api/Course/AssignCourseToTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseAssignCourseToTeacherPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAssignCourseToTeacherPost$Plain$Response(params?: ApiCourseAssignCourseToTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AssignCourseToTeacherCommandResult>> {
    return apiCourseAssignCourseToTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseAssignCourseToTeacherPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAssignCourseToTeacherPost$Plain(params?: ApiCourseAssignCourseToTeacherPost$Plain$Params, context?: HttpContext): Observable<AssignCourseToTeacherCommandResult> {
    return this.apiCourseAssignCourseToTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AssignCourseToTeacherCommandResult>): AssignCourseToTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseAssignCourseToTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAssignCourseToTeacherPost$Response(params?: ApiCourseAssignCourseToTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<AssignCourseToTeacherCommandResult>> {
    return apiCourseAssignCourseToTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseAssignCourseToTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCourseAssignCourseToTeacherPost(params?: ApiCourseAssignCourseToTeacherPost$Params, context?: HttpContext): Observable<AssignCourseToTeacherCommandResult> {
    return this.apiCourseAssignCourseToTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AssignCourseToTeacherCommandResult>): AssignCourseToTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiCourseDeleteCourseDelete()` */
  static readonly ApiCourseDeleteCourseDeletePath = '/api/Course/DeleteCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseDeleteCourseDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseDeleteCourseDelete$Plain$Response(params?: ApiCourseDeleteCourseDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCourseCommandResult>> {
    return apiCourseDeleteCourseDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseDeleteCourseDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseDeleteCourseDelete$Plain(params?: ApiCourseDeleteCourseDelete$Plain$Params, context?: HttpContext): Observable<DeleteCourseCommandResult> {
    return this.apiCourseDeleteCourseDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCourseCommandResult>): DeleteCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCourseDeleteCourseDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseDeleteCourseDelete$Response(params?: ApiCourseDeleteCourseDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCourseCommandResult>> {
    return apiCourseDeleteCourseDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCourseDeleteCourseDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCourseDeleteCourseDelete(params?: ApiCourseDeleteCourseDelete$Params, context?: HttpContext): Observable<DeleteCourseCommandResult> {
    return this.apiCourseDeleteCourseDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCourseCommandResult>): DeleteCourseCommandResult => r.body)
    );
  }

}
