/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AnswerQuestionCommandResult } from '../models/answer-question-command-result';
import { apiStudentAnswerQuestionPost } from '../fn/student/api-student-answer-question-post';
import { ApiStudentAnswerQuestionPost$Params } from '../fn/student/api-student-answer-question-post';
import { apiStudentAnswerQuestionPost$Plain } from '../fn/student/api-student-answer-question-post-plain';
import { ApiStudentAnswerQuestionPost$Plain$Params } from '../fn/student/api-student-answer-question-post-plain';
import { apiStudentCreateStudentPost } from '../fn/student/api-student-create-student-post';
import { ApiStudentCreateStudentPost$Params } from '../fn/student/api-student-create-student-post';
import { apiStudentCreateStudentPost$Plain } from '../fn/student/api-student-create-student-post-plain';
import { ApiStudentCreateStudentPost$Plain$Params } from '../fn/student/api-student-create-student-post-plain';
import { apiStudentDeleteStudentDelete } from '../fn/student/api-student-delete-student-delete';
import { ApiStudentDeleteStudentDelete$Params } from '../fn/student/api-student-delete-student-delete';
import { apiStudentDeleteStudentDelete$Plain } from '../fn/student/api-student-delete-student-delete-plain';
import { ApiStudentDeleteStudentDelete$Plain$Params } from '../fn/student/api-student-delete-student-delete-plain';
import { apiStudentGetCourseUnitPost } from '../fn/student/api-student-get-course-unit-post';
import { ApiStudentGetCourseUnitPost$Params } from '../fn/student/api-student-get-course-unit-post';
import { apiStudentGetCourseUnitPost$Plain } from '../fn/student/api-student-get-course-unit-post-plain';
import { ApiStudentGetCourseUnitPost$Plain$Params } from '../fn/student/api-student-get-course-unit-post-plain';
import { apiStudentGetMyCoursesPost } from '../fn/student/api-student-get-my-courses-post';
import { ApiStudentGetMyCoursesPost$Params } from '../fn/student/api-student-get-my-courses-post';
import { apiStudentGetMyCoursesPost$Plain } from '../fn/student/api-student-get-my-courses-post-plain';
import { ApiStudentGetMyCoursesPost$Plain$Params } from '../fn/student/api-student-get-my-courses-post-plain';
import { apiStudentGetMySolvedQuestionsPost } from '../fn/student/api-student-get-my-solved-questions-post';
import { ApiStudentGetMySolvedQuestionsPost$Params } from '../fn/student/api-student-get-my-solved-questions-post';
import { apiStudentGetMySolvedQuestionsPost$Plain } from '../fn/student/api-student-get-my-solved-questions-post-plain';
import { ApiStudentGetMySolvedQuestionsPost$Plain$Params } from '../fn/student/api-student-get-my-solved-questions-post-plain';
import { apiStudentGetMyWrongQuestionsPost } from '../fn/student/api-student-get-my-wrong-questions-post';
import { ApiStudentGetMyWrongQuestionsPost$Params } from '../fn/student/api-student-get-my-wrong-questions-post';
import { apiStudentGetMyWrongQuestionsPost$Plain } from '../fn/student/api-student-get-my-wrong-questions-post-plain';
import { ApiStudentGetMyWrongQuestionsPost$Plain$Params } from '../fn/student/api-student-get-my-wrong-questions-post-plain';
import { apiStudentGetStudentCoursesPost } from '../fn/student/api-student-get-student-courses-post';
import { ApiStudentGetStudentCoursesPost$Params } from '../fn/student/api-student-get-student-courses-post';
import { apiStudentGetStudentCoursesPost$Plain } from '../fn/student/api-student-get-student-courses-post-plain';
import { ApiStudentGetStudentCoursesPost$Plain$Params } from '../fn/student/api-student-get-student-courses-post-plain';
import { apiStudentGetStudentDashboardPost } from '../fn/student/api-student-get-student-dashboard-post';
import { ApiStudentGetStudentDashboardPost$Params } from '../fn/student/api-student-get-student-dashboard-post';
import { apiStudentGetStudentDashboardPost$Plain } from '../fn/student/api-student-get-student-dashboard-post-plain';
import { ApiStudentGetStudentDashboardPost$Plain$Params } from '../fn/student/api-student-get-student-dashboard-post-plain';
import { apiStudentGetStudentLessonsByUnitIdPost } from '../fn/student/api-student-get-student-lessons-by-unit-id-post';
import { ApiStudentGetStudentLessonsByUnitIdPost$Params } from '../fn/student/api-student-get-student-lessons-by-unit-id-post';
import { apiStudentGetStudentLessonsByUnitIdPost$Plain } from '../fn/student/api-student-get-student-lessons-by-unit-id-post-plain';
import { ApiStudentGetStudentLessonsByUnitIdPost$Plain$Params } from '../fn/student/api-student-get-student-lessons-by-unit-id-post-plain';
import { apiStudentGetStudentLessonsPost } from '../fn/student/api-student-get-student-lessons-post';
import { ApiStudentGetStudentLessonsPost$Params } from '../fn/student/api-student-get-student-lessons-post';
import { apiStudentGetStudentLessonsPost$Plain } from '../fn/student/api-student-get-student-lessons-post-plain';
import { ApiStudentGetStudentLessonsPost$Plain$Params } from '../fn/student/api-student-get-student-lessons-post-plain';
import { apiStudentGetStudentPost } from '../fn/student/api-student-get-student-post';
import { ApiStudentGetStudentPost$Params } from '../fn/student/api-student-get-student-post';
import { apiStudentGetStudentPost$Plain } from '../fn/student/api-student-get-student-post-plain';
import { ApiStudentGetStudentPost$Plain$Params } from '../fn/student/api-student-get-student-post-plain';
import { apiStudentGetStudentsPost } from '../fn/student/api-student-get-students-post';
import { ApiStudentGetStudentsPost$Params } from '../fn/student/api-student-get-students-post';
import { apiStudentGetStudentsPost$Plain } from '../fn/student/api-student-get-students-post-plain';
import { ApiStudentGetStudentsPost$Plain$Params } from '../fn/student/api-student-get-students-post-plain';
import { apiStudentStudentsReportPost } from '../fn/student/api-student-students-report-post';
import { ApiStudentStudentsReportPost$Params } from '../fn/student/api-student-students-report-post';
import { apiStudentStudentsReportPost$Plain } from '../fn/student/api-student-students-report-post-plain';
import { ApiStudentStudentsReportPost$Plain$Params } from '../fn/student/api-student-students-report-post-plain';
import { apiStudentUnSubscribeFromCoursePost } from '../fn/student/api-student-un-subscribe-from-course-post';
import { ApiStudentUnSubscribeFromCoursePost$Params } from '../fn/student/api-student-un-subscribe-from-course-post';
import { apiStudentUnSubscribeFromCoursePost$Plain } from '../fn/student/api-student-un-subscribe-from-course-post-plain';
import { ApiStudentUnSubscribeFromCoursePost$Plain$Params } from '../fn/student/api-student-un-subscribe-from-course-post-plain';
import { apiStudentUpdateCurrentStudentPost } from '../fn/student/api-student-update-current-student-post';
import { ApiStudentUpdateCurrentStudentPost$Params } from '../fn/student/api-student-update-current-student-post';
import { apiStudentUpdateCurrentStudentPost$Plain } from '../fn/student/api-student-update-current-student-post-plain';
import { ApiStudentUpdateCurrentStudentPost$Plain$Params } from '../fn/student/api-student-update-current-student-post-plain';
import { apiStudentUpdateStudentPost } from '../fn/student/api-student-update-student-post';
import { ApiStudentUpdateStudentPost$Params } from '../fn/student/api-student-update-student-post';
import { apiStudentUpdateStudentPost$Plain } from '../fn/student/api-student-update-student-post-plain';
import { ApiStudentUpdateStudentPost$Plain$Params } from '../fn/student/api-student-update-student-post-plain';
import { CreateStudentCommandResult } from '../models/create-student-command-result';
import { DeleteStudentCommandResult } from '../models/delete-student-command-result';
import { GetCourseUnitQueryResult } from '../models/get-course-unit-query-result';
import { GetLessonsByUnitIdQueryResult } from '../models/get-lessons-by-unit-id-query-result';
import { GetMySolvedQuestionsQueryResult } from '../models/get-my-solved-questions-query-result';
import { GetMyWrongQuestionsQueryResult } from '../models/get-my-wrong-questions-query-result';
import { GetStudentCoursesQueryResult } from '../models/get-student-courses-query-result';
import { GetStudentDashboardQueryResult } from '../models/get-student-dashboard-query-result';
import { GetStudentLessonsQueryResult } from '../models/get-student-lessons-query-result';
import { GetStudentMyCoursesQueryResult } from '../models/get-student-my-courses-query-result';
import { GetStudentQueryResult } from '../models/get-student-query-result';
import { GetStudentsQueryResult } from '../models/get-students-query-result';
import { UnSubscribeFromCourseCommandResult } from '../models/un-subscribe-from-course-command-result';
import { UpdateCurrentStudentCommandResult } from '../models/update-current-student-command-result';
import { UpdateStudentCommandResult } from '../models/update-student-command-result';

@Injectable({ providedIn: 'root' })
export class StudentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStudentGetStudentPost()` */
  static readonly ApiStudentGetStudentPostPath = '/api/Student/GetStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentPost$Plain$Response(params?: ApiStudentGetStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentQueryResult>> {
    return apiStudentGetStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentPost$Plain(params?: ApiStudentGetStudentPost$Plain$Params, context?: HttpContext): Observable<GetStudentQueryResult> {
    return this.apiStudentGetStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentQueryResult>): GetStudentQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentPost$Response(params?: ApiStudentGetStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentQueryResult>> {
    return apiStudentGetStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentPost(params?: ApiStudentGetStudentPost$Params, context?: HttpContext): Observable<GetStudentQueryResult> {
    return this.apiStudentGetStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentQueryResult>): GetStudentQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentsPost()` */
  static readonly ApiStudentGetStudentsPostPath = '/api/Student/GetStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentsPost$Plain$Response(params?: ApiStudentGetStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentsQueryResult>> {
    return apiStudentGetStudentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentsPost$Plain(params?: ApiStudentGetStudentsPost$Plain$Params, context?: HttpContext): Observable<GetStudentsQueryResult> {
    return this.apiStudentGetStudentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentsQueryResult>): GetStudentsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentsPost$Response(params?: ApiStudentGetStudentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentsQueryResult>> {
    return apiStudentGetStudentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentsPost(params?: ApiStudentGetStudentsPost$Params, context?: HttpContext): Observable<GetStudentsQueryResult> {
    return this.apiStudentGetStudentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentsQueryResult>): GetStudentsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentCoursesPost()` */
  static readonly ApiStudentGetStudentCoursesPostPath = '/api/Student/GetStudentCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentCoursesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentCoursesPost$Plain$Response(params?: ApiStudentGetStudentCoursesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentCoursesQueryResult>> {
    return apiStudentGetStudentCoursesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentCoursesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentCoursesPost$Plain(params?: ApiStudentGetStudentCoursesPost$Plain$Params, context?: HttpContext): Observable<GetStudentCoursesQueryResult> {
    return this.apiStudentGetStudentCoursesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentCoursesQueryResult>): GetStudentCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentCoursesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentCoursesPost$Response(params?: ApiStudentGetStudentCoursesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentCoursesQueryResult>> {
    return apiStudentGetStudentCoursesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentCoursesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentCoursesPost(params?: ApiStudentGetStudentCoursesPost$Params, context?: HttpContext): Observable<GetStudentCoursesQueryResult> {
    return this.apiStudentGetStudentCoursesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentCoursesQueryResult>): GetStudentCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetMyCoursesPost()` */
  static readonly ApiStudentGetMyCoursesPostPath = '/api/Student/GetMyCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMyCoursesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyCoursesPost$Plain$Response(params?: ApiStudentGetMyCoursesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentMyCoursesQueryResult>> {
    return apiStudentGetMyCoursesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMyCoursesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyCoursesPost$Plain(params?: ApiStudentGetMyCoursesPost$Plain$Params, context?: HttpContext): Observable<GetStudentMyCoursesQueryResult> {
    return this.apiStudentGetMyCoursesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentMyCoursesQueryResult>): GetStudentMyCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMyCoursesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyCoursesPost$Response(params?: ApiStudentGetMyCoursesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentMyCoursesQueryResult>> {
    return apiStudentGetMyCoursesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMyCoursesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyCoursesPost(params?: ApiStudentGetMyCoursesPost$Params, context?: HttpContext): Observable<GetStudentMyCoursesQueryResult> {
    return this.apiStudentGetMyCoursesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentMyCoursesQueryResult>): GetStudentMyCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetMySolvedQuestionsPost()` */
  static readonly ApiStudentGetMySolvedQuestionsPostPath = '/api/Student/GetMySolvedQuestions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMySolvedQuestionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMySolvedQuestionsPost$Plain$Response(params?: ApiStudentGetMySolvedQuestionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMySolvedQuestionsQueryResult>> {
    return apiStudentGetMySolvedQuestionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMySolvedQuestionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMySolvedQuestionsPost$Plain(params?: ApiStudentGetMySolvedQuestionsPost$Plain$Params, context?: HttpContext): Observable<GetMySolvedQuestionsQueryResult> {
    return this.apiStudentGetMySolvedQuestionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMySolvedQuestionsQueryResult>): GetMySolvedQuestionsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMySolvedQuestionsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMySolvedQuestionsPost$Response(params?: ApiStudentGetMySolvedQuestionsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMySolvedQuestionsQueryResult>> {
    return apiStudentGetMySolvedQuestionsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMySolvedQuestionsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMySolvedQuestionsPost(params?: ApiStudentGetMySolvedQuestionsPost$Params, context?: HttpContext): Observable<GetMySolvedQuestionsQueryResult> {
    return this.apiStudentGetMySolvedQuestionsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMySolvedQuestionsQueryResult>): GetMySolvedQuestionsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetMyWrongQuestionsPost()` */
  static readonly ApiStudentGetMyWrongQuestionsPostPath = '/api/Student/GetMyWrongQuestions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMyWrongQuestionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyWrongQuestionsPost$Plain$Response(params?: ApiStudentGetMyWrongQuestionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMyWrongQuestionsQueryResult>> {
    return apiStudentGetMyWrongQuestionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMyWrongQuestionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyWrongQuestionsPost$Plain(params?: ApiStudentGetMyWrongQuestionsPost$Plain$Params, context?: HttpContext): Observable<GetMyWrongQuestionsQueryResult> {
    return this.apiStudentGetMyWrongQuestionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMyWrongQuestionsQueryResult>): GetMyWrongQuestionsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetMyWrongQuestionsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyWrongQuestionsPost$Response(params?: ApiStudentGetMyWrongQuestionsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMyWrongQuestionsQueryResult>> {
    return apiStudentGetMyWrongQuestionsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetMyWrongQuestionsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetMyWrongQuestionsPost(params?: ApiStudentGetMyWrongQuestionsPost$Params, context?: HttpContext): Observable<GetMyWrongQuestionsQueryResult> {
    return this.apiStudentGetMyWrongQuestionsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMyWrongQuestionsQueryResult>): GetMyWrongQuestionsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentLessonsPost()` */
  static readonly ApiStudentGetStudentLessonsPostPath = '/api/Student/GetStudentLessons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentLessonsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsPost$Plain$Response(params?: ApiStudentGetStudentLessonsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentLessonsQueryResult>> {
    return apiStudentGetStudentLessonsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentLessonsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsPost$Plain(params?: ApiStudentGetStudentLessonsPost$Plain$Params, context?: HttpContext): Observable<GetStudentLessonsQueryResult> {
    return this.apiStudentGetStudentLessonsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentLessonsQueryResult>): GetStudentLessonsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentLessonsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsPost$Response(params?: ApiStudentGetStudentLessonsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentLessonsQueryResult>> {
    return apiStudentGetStudentLessonsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentLessonsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsPost(params?: ApiStudentGetStudentLessonsPost$Params, context?: HttpContext): Observable<GetStudentLessonsQueryResult> {
    return this.apiStudentGetStudentLessonsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentLessonsQueryResult>): GetStudentLessonsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentLessonsByUnitIdPost()` */
  static readonly ApiStudentGetStudentLessonsByUnitIdPostPath = '/api/Student/GetStudentLessonsByUnitId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentLessonsByUnitIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsByUnitIdPost$Plain$Response(params?: ApiStudentGetStudentLessonsByUnitIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonsByUnitIdQueryResult>> {
    return apiStudentGetStudentLessonsByUnitIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentLessonsByUnitIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsByUnitIdPost$Plain(params?: ApiStudentGetStudentLessonsByUnitIdPost$Plain$Params, context?: HttpContext): Observable<GetLessonsByUnitIdQueryResult> {
    return this.apiStudentGetStudentLessonsByUnitIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonsByUnitIdQueryResult>): GetLessonsByUnitIdQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentLessonsByUnitIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsByUnitIdPost$Response(params?: ApiStudentGetStudentLessonsByUnitIdPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetLessonsByUnitIdQueryResult>> {
    return apiStudentGetStudentLessonsByUnitIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentLessonsByUnitIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentLessonsByUnitIdPost(params?: ApiStudentGetStudentLessonsByUnitIdPost$Params, context?: HttpContext): Observable<GetLessonsByUnitIdQueryResult> {
    return this.apiStudentGetStudentLessonsByUnitIdPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetLessonsByUnitIdQueryResult>): GetLessonsByUnitIdQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetCourseUnitPost()` */
  static readonly ApiStudentGetCourseUnitPostPath = '/api/Student/GetCourseUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCourseUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetCourseUnitPost$Plain$Response(params?: ApiStudentGetCourseUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseUnitQueryResult>> {
    return apiStudentGetCourseUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCourseUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetCourseUnitPost$Plain(params?: ApiStudentGetCourseUnitPost$Plain$Params, context?: HttpContext): Observable<GetCourseUnitQueryResult> {
    return this.apiStudentGetCourseUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseUnitQueryResult>): GetCourseUnitQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetCourseUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetCourseUnitPost$Response(params?: ApiStudentGetCourseUnitPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseUnitQueryResult>> {
    return apiStudentGetCourseUnitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetCourseUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetCourseUnitPost(params?: ApiStudentGetCourseUnitPost$Params, context?: HttpContext): Observable<GetCourseUnitQueryResult> {
    return this.apiStudentGetCourseUnitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseUnitQueryResult>): GetCourseUnitQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentGetStudentDashboardPost()` */
  static readonly ApiStudentGetStudentDashboardPostPath = '/api/Student/GetStudentDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentDashboardPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentDashboardPost$Plain$Response(params?: ApiStudentGetStudentDashboardPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentDashboardQueryResult>> {
    return apiStudentGetStudentDashboardPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentDashboardPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentDashboardPost$Plain(params?: ApiStudentGetStudentDashboardPost$Plain$Params, context?: HttpContext): Observable<GetStudentDashboardQueryResult> {
    return this.apiStudentGetStudentDashboardPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentDashboardQueryResult>): GetStudentDashboardQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentGetStudentDashboardPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentDashboardPost$Response(params?: ApiStudentGetStudentDashboardPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentDashboardQueryResult>> {
    return apiStudentGetStudentDashboardPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentGetStudentDashboardPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentGetStudentDashboardPost(params?: ApiStudentGetStudentDashboardPost$Params, context?: HttpContext): Observable<GetStudentDashboardQueryResult> {
    return this.apiStudentGetStudentDashboardPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentDashboardQueryResult>): GetStudentDashboardQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentAnswerQuestionPost()` */
  static readonly ApiStudentAnswerQuestionPostPath = '/api/Student/AnswerQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentAnswerQuestionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAnswerQuestionPost$Plain$Response(params?: ApiStudentAnswerQuestionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AnswerQuestionCommandResult>> {
    return apiStudentAnswerQuestionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentAnswerQuestionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAnswerQuestionPost$Plain(params?: ApiStudentAnswerQuestionPost$Plain$Params, context?: HttpContext): Observable<AnswerQuestionCommandResult> {
    return this.apiStudentAnswerQuestionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnswerQuestionCommandResult>): AnswerQuestionCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentAnswerQuestionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAnswerQuestionPost$Response(params?: ApiStudentAnswerQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<AnswerQuestionCommandResult>> {
    return apiStudentAnswerQuestionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentAnswerQuestionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentAnswerQuestionPost(params?: ApiStudentAnswerQuestionPost$Params, context?: HttpContext): Observable<AnswerQuestionCommandResult> {
    return this.apiStudentAnswerQuestionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnswerQuestionCommandResult>): AnswerQuestionCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentCreateStudentPost()` */
  static readonly ApiStudentCreateStudentPostPath = '/api/Student/CreateStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentCreateStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentCreateStudentPost$Plain$Response(params?: ApiStudentCreateStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentCommandResult>> {
    return apiStudentCreateStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentCreateStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentCreateStudentPost$Plain(params?: ApiStudentCreateStudentPost$Plain$Params, context?: HttpContext): Observable<CreateStudentCommandResult> {
    return this.apiStudentCreateStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentCommandResult>): CreateStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentCreateStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentCreateStudentPost$Response(params?: ApiStudentCreateStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentCommandResult>> {
    return apiStudentCreateStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentCreateStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentCreateStudentPost(params?: ApiStudentCreateStudentPost$Params, context?: HttpContext): Observable<CreateStudentCommandResult> {
    return this.apiStudentCreateStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentCommandResult>): CreateStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentUpdateStudentPost()` */
  static readonly ApiStudentUpdateStudentPostPath = '/api/Student/UpdateStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUpdateStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateStudentPost$Plain$Response(params?: ApiStudentUpdateStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStudentCommandResult>> {
    return apiStudentUpdateStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUpdateStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateStudentPost$Plain(params?: ApiStudentUpdateStudentPost$Plain$Params, context?: HttpContext): Observable<UpdateStudentCommandResult> {
    return this.apiStudentUpdateStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStudentCommandResult>): UpdateStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUpdateStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateStudentPost$Response(params?: ApiStudentUpdateStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStudentCommandResult>> {
    return apiStudentUpdateStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUpdateStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateStudentPost(params?: ApiStudentUpdateStudentPost$Params, context?: HttpContext): Observable<UpdateStudentCommandResult> {
    return this.apiStudentUpdateStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStudentCommandResult>): UpdateStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentUpdateCurrentStudentPost()` */
  static readonly ApiStudentUpdateCurrentStudentPostPath = '/api/Student/UpdateCurrentStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUpdateCurrentStudentPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateCurrentStudentPost$Plain$Response(params?: ApiStudentUpdateCurrentStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCurrentStudentCommandResult>> {
    return apiStudentUpdateCurrentStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUpdateCurrentStudentPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateCurrentStudentPost$Plain(params?: ApiStudentUpdateCurrentStudentPost$Plain$Params, context?: HttpContext): Observable<UpdateCurrentStudentCommandResult> {
    return this.apiStudentUpdateCurrentStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCurrentStudentCommandResult>): UpdateCurrentStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUpdateCurrentStudentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateCurrentStudentPost$Response(params?: ApiStudentUpdateCurrentStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCurrentStudentCommandResult>> {
    return apiStudentUpdateCurrentStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUpdateCurrentStudentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUpdateCurrentStudentPost(params?: ApiStudentUpdateCurrentStudentPost$Params, context?: HttpContext): Observable<UpdateCurrentStudentCommandResult> {
    return this.apiStudentUpdateCurrentStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCurrentStudentCommandResult>): UpdateCurrentStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentUnSubscribeFromCoursePost()` */
  static readonly ApiStudentUnSubscribeFromCoursePostPath = '/api/Student/UnSubscribeFromCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUnSubscribeFromCoursePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUnSubscribeFromCoursePost$Plain$Response(params?: ApiStudentUnSubscribeFromCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UnSubscribeFromCourseCommandResult>> {
    return apiStudentUnSubscribeFromCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUnSubscribeFromCoursePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUnSubscribeFromCoursePost$Plain(params?: ApiStudentUnSubscribeFromCoursePost$Plain$Params, context?: HttpContext): Observable<UnSubscribeFromCourseCommandResult> {
    return this.apiStudentUnSubscribeFromCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnSubscribeFromCourseCommandResult>): UnSubscribeFromCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentUnSubscribeFromCoursePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUnSubscribeFromCoursePost$Response(params?: ApiStudentUnSubscribeFromCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<UnSubscribeFromCourseCommandResult>> {
    return apiStudentUnSubscribeFromCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentUnSubscribeFromCoursePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiStudentUnSubscribeFromCoursePost(params?: ApiStudentUnSubscribeFromCoursePost$Params, context?: HttpContext): Observable<UnSubscribeFromCourseCommandResult> {
    return this.apiStudentUnSubscribeFromCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnSubscribeFromCourseCommandResult>): UnSubscribeFromCourseCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentDeleteStudentDelete()` */
  static readonly ApiStudentDeleteStudentDeletePath = '/api/Student/DeleteStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentDeleteStudentDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentDeleteStudentDelete$Plain$Response(params?: ApiStudentDeleteStudentDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStudentCommandResult>> {
    return apiStudentDeleteStudentDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentDeleteStudentDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentDeleteStudentDelete$Plain(params?: ApiStudentDeleteStudentDelete$Plain$Params, context?: HttpContext): Observable<DeleteStudentCommandResult> {
    return this.apiStudentDeleteStudentDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStudentCommandResult>): DeleteStudentCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentDeleteStudentDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentDeleteStudentDelete$Response(params?: ApiStudentDeleteStudentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStudentCommandResult>> {
    return apiStudentDeleteStudentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentDeleteStudentDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentDeleteStudentDelete(params?: ApiStudentDeleteStudentDelete$Params, context?: HttpContext): Observable<DeleteStudentCommandResult> {
    return this.apiStudentDeleteStudentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStudentCommandResult>): DeleteStudentCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentStudentsReportPost()` */
  static readonly ApiStudentStudentsReportPostPath = '/api/Student/StudentsReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentStudentsReportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentStudentsReportPost$Plain$Response(params?: ApiStudentStudentsReportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiStudentStudentsReportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentStudentsReportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentStudentsReportPost$Plain(params?: ApiStudentStudentsReportPost$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.apiStudentStudentsReportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentStudentsReportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentStudentsReportPost$Response(params?: ApiStudentStudentsReportPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiStudentStudentsReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentStudentsReportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentStudentsReportPost(params?: ApiStudentStudentsReportPost$Params, context?: HttpContext): Observable<Blob> {
    return this.apiStudentStudentsReportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
