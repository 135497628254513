/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiFeedbacksDeleteFeedbackPost } from '../fn/feedbacks/api-feedbacks-delete-feedback-post';
import { ApiFeedbacksDeleteFeedbackPost$Params } from '../fn/feedbacks/api-feedbacks-delete-feedback-post';
import { apiFeedbacksDeleteFeedbackPost$Plain } from '../fn/feedbacks/api-feedbacks-delete-feedback-post-plain';
import { ApiFeedbacksDeleteFeedbackPost$Plain$Params } from '../fn/feedbacks/api-feedbacks-delete-feedback-post-plain';
import { apiFeedbacksGetFeedbacksPost } from '../fn/feedbacks/api-feedbacks-get-feedbacks-post';
import { ApiFeedbacksGetFeedbacksPost$Params } from '../fn/feedbacks/api-feedbacks-get-feedbacks-post';
import { apiFeedbacksGetFeedbacksPost$Plain } from '../fn/feedbacks/api-feedbacks-get-feedbacks-post-plain';
import { ApiFeedbacksGetFeedbacksPost$Plain$Params } from '../fn/feedbacks/api-feedbacks-get-feedbacks-post-plain';
import { apiFeedbacksSendFeedbackPost } from '../fn/feedbacks/api-feedbacks-send-feedback-post';
import { ApiFeedbacksSendFeedbackPost$Params } from '../fn/feedbacks/api-feedbacks-send-feedback-post';
import { apiFeedbacksSendFeedbackPost$Plain } from '../fn/feedbacks/api-feedbacks-send-feedback-post-plain';
import { ApiFeedbacksSendFeedbackPost$Plain$Params } from '../fn/feedbacks/api-feedbacks-send-feedback-post-plain';
import { apiFeedbacksToggleFeedbackStatusPost } from '../fn/feedbacks/api-feedbacks-toggle-feedback-status-post';
import { ApiFeedbacksToggleFeedbackStatusPost$Params } from '../fn/feedbacks/api-feedbacks-toggle-feedback-status-post';
import { apiFeedbacksToggleFeedbackStatusPost$Plain } from '../fn/feedbacks/api-feedbacks-toggle-feedback-status-post-plain';
import { ApiFeedbacksToggleFeedbackStatusPost$Plain$Params } from '../fn/feedbacks/api-feedbacks-toggle-feedback-status-post-plain';
import { CreateFeedbackCommandResult } from '../models/create-feedback-command-result';
import { DeleteFeedbackCommandResult } from '../models/delete-feedback-command-result';
import { GetFeedbacksQueryResult } from '../models/get-feedbacks-query-result';
import { ToggleFeedbackStatusCommandResult } from '../models/toggle-feedback-status-command-result';

@Injectable({ providedIn: 'root' })
export class FeedbacksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiFeedbacksGetFeedbacksPost()` */
  static readonly ApiFeedbacksGetFeedbacksPostPath = '/api/Feedbacks/GetFeedbacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksGetFeedbacksPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksGetFeedbacksPost$Plain$Response(params?: ApiFeedbacksGetFeedbacksPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetFeedbacksQueryResult>> {
    return apiFeedbacksGetFeedbacksPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksGetFeedbacksPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksGetFeedbacksPost$Plain(params?: ApiFeedbacksGetFeedbacksPost$Plain$Params, context?: HttpContext): Observable<GetFeedbacksQueryResult> {
    return this.apiFeedbacksGetFeedbacksPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetFeedbacksQueryResult>): GetFeedbacksQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksGetFeedbacksPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksGetFeedbacksPost$Response(params?: ApiFeedbacksGetFeedbacksPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetFeedbacksQueryResult>> {
    return apiFeedbacksGetFeedbacksPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksGetFeedbacksPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksGetFeedbacksPost(params?: ApiFeedbacksGetFeedbacksPost$Params, context?: HttpContext): Observable<GetFeedbacksQueryResult> {
    return this.apiFeedbacksGetFeedbacksPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetFeedbacksQueryResult>): GetFeedbacksQueryResult => r.body)
    );
  }

  /** Path part for operation `apiFeedbacksSendFeedbackPost()` */
  static readonly ApiFeedbacksSendFeedbackPostPath = '/api/Feedbacks/SendFeedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksSendFeedbackPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksSendFeedbackPost$Plain$Response(params?: ApiFeedbacksSendFeedbackPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateFeedbackCommandResult>> {
    return apiFeedbacksSendFeedbackPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksSendFeedbackPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksSendFeedbackPost$Plain(params?: ApiFeedbacksSendFeedbackPost$Plain$Params, context?: HttpContext): Observable<CreateFeedbackCommandResult> {
    return this.apiFeedbacksSendFeedbackPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateFeedbackCommandResult>): CreateFeedbackCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksSendFeedbackPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksSendFeedbackPost$Response(params?: ApiFeedbacksSendFeedbackPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateFeedbackCommandResult>> {
    return apiFeedbacksSendFeedbackPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksSendFeedbackPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksSendFeedbackPost(params?: ApiFeedbacksSendFeedbackPost$Params, context?: HttpContext): Observable<CreateFeedbackCommandResult> {
    return this.apiFeedbacksSendFeedbackPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateFeedbackCommandResult>): CreateFeedbackCommandResult => r.body)
    );
  }

  /** Path part for operation `apiFeedbacksDeleteFeedbackPost()` */
  static readonly ApiFeedbacksDeleteFeedbackPostPath = '/api/Feedbacks/DeleteFeedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksDeleteFeedbackPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksDeleteFeedbackPost$Plain$Response(params?: ApiFeedbacksDeleteFeedbackPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteFeedbackCommandResult>> {
    return apiFeedbacksDeleteFeedbackPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksDeleteFeedbackPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksDeleteFeedbackPost$Plain(params?: ApiFeedbacksDeleteFeedbackPost$Plain$Params, context?: HttpContext): Observable<DeleteFeedbackCommandResult> {
    return this.apiFeedbacksDeleteFeedbackPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteFeedbackCommandResult>): DeleteFeedbackCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksDeleteFeedbackPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksDeleteFeedbackPost$Response(params?: ApiFeedbacksDeleteFeedbackPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteFeedbackCommandResult>> {
    return apiFeedbacksDeleteFeedbackPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksDeleteFeedbackPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksDeleteFeedbackPost(params?: ApiFeedbacksDeleteFeedbackPost$Params, context?: HttpContext): Observable<DeleteFeedbackCommandResult> {
    return this.apiFeedbacksDeleteFeedbackPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteFeedbackCommandResult>): DeleteFeedbackCommandResult => r.body)
    );
  }

  /** Path part for operation `apiFeedbacksToggleFeedbackStatusPost()` */
  static readonly ApiFeedbacksToggleFeedbackStatusPostPath = '/api/Feedbacks/ToggleFeedbackStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksToggleFeedbackStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksToggleFeedbackStatusPost$Plain$Response(params?: ApiFeedbacksToggleFeedbackStatusPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ToggleFeedbackStatusCommandResult>> {
    return apiFeedbacksToggleFeedbackStatusPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksToggleFeedbackStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksToggleFeedbackStatusPost$Plain(params?: ApiFeedbacksToggleFeedbackStatusPost$Plain$Params, context?: HttpContext): Observable<ToggleFeedbackStatusCommandResult> {
    return this.apiFeedbacksToggleFeedbackStatusPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleFeedbackStatusCommandResult>): ToggleFeedbackStatusCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFeedbacksToggleFeedbackStatusPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksToggleFeedbackStatusPost$Response(params?: ApiFeedbacksToggleFeedbackStatusPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ToggleFeedbackStatusCommandResult>> {
    return apiFeedbacksToggleFeedbackStatusPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFeedbacksToggleFeedbackStatusPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFeedbacksToggleFeedbackStatusPost(params?: ApiFeedbacksToggleFeedbackStatusPost$Params, context?: HttpContext): Observable<ToggleFeedbackStatusCommandResult> {
    return this.apiFeedbacksToggleFeedbackStatusPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleFeedbackStatusCommandResult>): ToggleFeedbackStatusCommandResult => r.body)
    );
  }

}
