import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, MgApplicationUser } from 'src/app/core/services/auth.service';

@Component({
  selector: 'mg-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent {
  acc: MgApplicationUser | null = null;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
  ) {
    auth.identity(true).subscribe({
      next: (acc) => {
        this.acc = acc;
      }
    })
  }


  
  logout() {
    this.auth.logout();
  }

  redirectUser() {
    if (this.auth.currentUserSubject.getValue()) {
      this.auth.redirectUser();
    } else {
      this.router.navigate(['/account/login']);
    }
  }
}
