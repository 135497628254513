/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddStudentToCourseCommandResult } from '../models/add-student-to-course-command-result';
import { apiTeacherAddStudentToCoursePost } from '../fn/teacher/api-teacher-add-student-to-course-post';
import { ApiTeacherAddStudentToCoursePost$Params } from '../fn/teacher/api-teacher-add-student-to-course-post';
import { apiTeacherAddStudentToCoursePost$Plain } from '../fn/teacher/api-teacher-add-student-to-course-post-plain';
import { ApiTeacherAddStudentToCoursePost$Plain$Params } from '../fn/teacher/api-teacher-add-student-to-course-post-plain';
import { apiTeacherCreateTeacherPost } from '../fn/teacher/api-teacher-create-teacher-post';
import { ApiTeacherCreateTeacherPost$Params } from '../fn/teacher/api-teacher-create-teacher-post';
import { apiTeacherCreateTeacherPost$Plain } from '../fn/teacher/api-teacher-create-teacher-post-plain';
import { ApiTeacherCreateTeacherPost$Plain$Params } from '../fn/teacher/api-teacher-create-teacher-post-plain';
import { apiTeacherDeleteCourseTeacherDelete } from '../fn/teacher/api-teacher-delete-course-teacher-delete';
import { ApiTeacherDeleteCourseTeacherDelete$Params } from '../fn/teacher/api-teacher-delete-course-teacher-delete';
import { apiTeacherDeleteCourseTeacherDelete$Plain } from '../fn/teacher/api-teacher-delete-course-teacher-delete-plain';
import { ApiTeacherDeleteCourseTeacherDelete$Plain$Params } from '../fn/teacher/api-teacher-delete-course-teacher-delete-plain';
import { apiTeacherDeleteTeacherDelete } from '../fn/teacher/api-teacher-delete-teacher-delete';
import { ApiTeacherDeleteTeacherDelete$Params } from '../fn/teacher/api-teacher-delete-teacher-delete';
import { apiTeacherDeleteTeacherDelete$Plain } from '../fn/teacher/api-teacher-delete-teacher-delete-plain';
import { ApiTeacherDeleteTeacherDelete$Plain$Params } from '../fn/teacher/api-teacher-delete-teacher-delete-plain';
import { apiTeacherFreezeOrUnFreezeCourseTeacherPost } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-post';
import { ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Params } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-post';
import { apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-post-plain';
import { ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Params } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-post-plain';
import { apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-student-post';
import { ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Params } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-student-post';
import { apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-student-post-plain';
import { ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Params } from '../fn/teacher/api-teacher-freeze-or-un-freeze-course-teacher-student-post-plain';
import { apiTeacherGetMyCoursesPost } from '../fn/teacher/api-teacher-get-my-courses-post';
import { ApiTeacherGetMyCoursesPost$Params } from '../fn/teacher/api-teacher-get-my-courses-post';
import { apiTeacherGetMyCoursesPost$Plain } from '../fn/teacher/api-teacher-get-my-courses-post-plain';
import { ApiTeacherGetMyCoursesPost$Plain$Params } from '../fn/teacher/api-teacher-get-my-courses-post-plain';
import { apiTeacherGetRewardWallPost } from '../fn/teacher/api-teacher-get-reward-wall-post';
import { ApiTeacherGetRewardWallPost$Params } from '../fn/teacher/api-teacher-get-reward-wall-post';
import { apiTeacherGetRewardWallPost$Plain } from '../fn/teacher/api-teacher-get-reward-wall-post-plain';
import { ApiTeacherGetRewardWallPost$Plain$Params } from '../fn/teacher/api-teacher-get-reward-wall-post-plain';
import { apiTeacherGetTeacherCoursesPost } from '../fn/teacher/api-teacher-get-teacher-courses-post';
import { ApiTeacherGetTeacherCoursesPost$Params } from '../fn/teacher/api-teacher-get-teacher-courses-post';
import { apiTeacherGetTeacherCoursesPost$Plain } from '../fn/teacher/api-teacher-get-teacher-courses-post-plain';
import { ApiTeacherGetTeacherCoursesPost$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-courses-post-plain';
import { apiTeacherGetTeacherDashboardPost } from '../fn/teacher/api-teacher-get-teacher-dashboard-post';
import { ApiTeacherGetTeacherDashboardPost$Params } from '../fn/teacher/api-teacher-get-teacher-dashboard-post';
import { apiTeacherGetTeacherDashboardPost$Plain } from '../fn/teacher/api-teacher-get-teacher-dashboard-post-plain';
import { ApiTeacherGetTeacherDashboardPost$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-dashboard-post-plain';
import { apiTeacherGetTeacherPost } from '../fn/teacher/api-teacher-get-teacher-post';
import { ApiTeacherGetTeacherPost$Params } from '../fn/teacher/api-teacher-get-teacher-post';
import { apiTeacherGetTeacherPost$Plain } from '../fn/teacher/api-teacher-get-teacher-post-plain';
import { ApiTeacherGetTeacherPost$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-post-plain';
import { apiTeacherGetTeachersPost } from '../fn/teacher/api-teacher-get-teachers-post';
import { ApiTeacherGetTeachersPost$Params } from '../fn/teacher/api-teacher-get-teachers-post';
import { apiTeacherGetTeachersPost$Plain } from '../fn/teacher/api-teacher-get-teachers-post-plain';
import { ApiTeacherGetTeachersPost$Plain$Params } from '../fn/teacher/api-teacher-get-teachers-post-plain';
import { apiTeacherGetTeacherStudentsPost } from '../fn/teacher/api-teacher-get-teacher-students-post';
import { ApiTeacherGetTeacherStudentsPost$Params } from '../fn/teacher/api-teacher-get-teacher-students-post';
import { apiTeacherGetTeacherStudentsPost$Plain } from '../fn/teacher/api-teacher-get-teacher-students-post-plain';
import { ApiTeacherGetTeacherStudentsPost$Plain$Params } from '../fn/teacher/api-teacher-get-teacher-students-post-plain';
import { apiTeacherUpdateCourseTeacherImagePost } from '../fn/teacher/api-teacher-update-course-teacher-image-post';
import { ApiTeacherUpdateCourseTeacherImagePost$Params } from '../fn/teacher/api-teacher-update-course-teacher-image-post';
import { apiTeacherUpdateCourseTeacherImagePost$Plain } from '../fn/teacher/api-teacher-update-course-teacher-image-post-plain';
import { ApiTeacherUpdateCourseTeacherImagePost$Plain$Params } from '../fn/teacher/api-teacher-update-course-teacher-image-post-plain';
import { apiTeacherUpdateCurrentTeacherPost } from '../fn/teacher/api-teacher-update-current-teacher-post';
import { ApiTeacherUpdateCurrentTeacherPost$Params } from '../fn/teacher/api-teacher-update-current-teacher-post';
import { apiTeacherUpdateCurrentTeacherPost$Plain } from '../fn/teacher/api-teacher-update-current-teacher-post-plain';
import { ApiTeacherUpdateCurrentTeacherPost$Plain$Params } from '../fn/teacher/api-teacher-update-current-teacher-post-plain';
import { apiTeacherUpdateTeacherPost } from '../fn/teacher/api-teacher-update-teacher-post';
import { ApiTeacherUpdateTeacherPost$Params } from '../fn/teacher/api-teacher-update-teacher-post';
import { apiTeacherUpdateTeacherPost$Plain } from '../fn/teacher/api-teacher-update-teacher-post-plain';
import { ApiTeacherUpdateTeacherPost$Plain$Params } from '../fn/teacher/api-teacher-update-teacher-post-plain';
import { CreateTeacherCommandResult } from '../models/create-teacher-command-result';
import { DeleteCourseTeacherCommandResult } from '../models/delete-course-teacher-command-result';
import { DeleteTeacherCommandResult } from '../models/delete-teacher-command-result';
import { FreezeCourseTeacherCommandResult } from '../models/freeze-course-teacher-command-result';
import { FreezeStudentFromCourseCommandResult } from '../models/freeze-student-from-course-command-result';
import { GetRewardWallQueryResult } from '../models/get-reward-wall-query-result';
import { GetTeacherCoursesQueryResult } from '../models/get-teacher-courses-query-result';
import { GetTeacherDashboardQueryResult } from '../models/get-teacher-dashboard-query-result';
import { GetTeacherQueryResult } from '../models/get-teacher-query-result';
import { GetTeachersQueryResult } from '../models/get-teachers-query-result';
import { GetTeacherStudentsQueryResult } from '../models/get-teacher-students-query-result';
import { UpdateCourseTeacherImageCommandResult } from '../models/update-course-teacher-image-command-result';
import { UpdateCurrentTeacherCommandResult } from '../models/update-current-teacher-command-result';
import { UpdateTeacherCommandResult } from '../models/update-teacher-command-result';

@Injectable({ providedIn: 'root' })
export class TeacherService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTeacherGetTeacherPost()` */
  static readonly ApiTeacherGetTeacherPostPath = '/api/Teacher/GetTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherPost$Plain$Response(params?: ApiTeacherGetTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherQueryResult>> {
    return apiTeacherGetTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherPost$Plain(params?: ApiTeacherGetTeacherPost$Plain$Params, context?: HttpContext): Observable<GetTeacherQueryResult> {
    return this.apiTeacherGetTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherQueryResult>): GetTeacherQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherPost$Response(params?: ApiTeacherGetTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherQueryResult>> {
    return apiTeacherGetTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherPost(params?: ApiTeacherGetTeacherPost$Params, context?: HttpContext): Observable<GetTeacherQueryResult> {
    return this.apiTeacherGetTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherQueryResult>): GetTeacherQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeachersPost()` */
  static readonly ApiTeacherGetTeachersPostPath = '/api/Teacher/GetTeachers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeachersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeachersPost$Plain$Response(params?: ApiTeacherGetTeachersPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeachersQueryResult>> {
    return apiTeacherGetTeachersPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeachersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeachersPost$Plain(params?: ApiTeacherGetTeachersPost$Plain$Params, context?: HttpContext): Observable<GetTeachersQueryResult> {
    return this.apiTeacherGetTeachersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeachersQueryResult>): GetTeachersQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeachersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeachersPost$Response(params?: ApiTeacherGetTeachersPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeachersQueryResult>> {
    return apiTeacherGetTeachersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeachersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeachersPost(params?: ApiTeacherGetTeachersPost$Params, context?: HttpContext): Observable<GetTeachersQueryResult> {
    return this.apiTeacherGetTeachersPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeachersQueryResult>): GetTeachersQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeacherCoursesPost()` */
  static readonly ApiTeacherGetTeacherCoursesPostPath = '/api/Teacher/GetTeacherCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherCoursesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherCoursesPost$Plain$Response(params?: ApiTeacherGetTeacherCoursesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherCoursesQueryResult>> {
    return apiTeacherGetTeacherCoursesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherCoursesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherCoursesPost$Plain(params?: ApiTeacherGetTeacherCoursesPost$Plain$Params, context?: HttpContext): Observable<GetTeacherCoursesQueryResult> {
    return this.apiTeacherGetTeacherCoursesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherCoursesQueryResult>): GetTeacherCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherCoursesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherCoursesPost$Response(params?: ApiTeacherGetTeacherCoursesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherCoursesQueryResult>> {
    return apiTeacherGetTeacherCoursesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherCoursesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherCoursesPost(params?: ApiTeacherGetTeacherCoursesPost$Params, context?: HttpContext): Observable<GetTeacherCoursesQueryResult> {
    return this.apiTeacherGetTeacherCoursesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherCoursesQueryResult>): GetTeacherCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetMyCoursesPost()` */
  static readonly ApiTeacherGetMyCoursesPostPath = '/api/Teacher/GetMyCourses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetMyCoursesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetMyCoursesPost$Plain$Response(params?: ApiTeacherGetMyCoursesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherCoursesQueryResult>> {
    return apiTeacherGetMyCoursesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetMyCoursesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetMyCoursesPost$Plain(params?: ApiTeacherGetMyCoursesPost$Plain$Params, context?: HttpContext): Observable<GetTeacherCoursesQueryResult> {
    return this.apiTeacherGetMyCoursesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherCoursesQueryResult>): GetTeacherCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetMyCoursesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetMyCoursesPost$Response(params?: ApiTeacherGetMyCoursesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherCoursesQueryResult>> {
    return apiTeacherGetMyCoursesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetMyCoursesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetMyCoursesPost(params?: ApiTeacherGetMyCoursesPost$Params, context?: HttpContext): Observable<GetTeacherCoursesQueryResult> {
    return this.apiTeacherGetMyCoursesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherCoursesQueryResult>): GetTeacherCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeacherStudentsPost()` */
  static readonly ApiTeacherGetTeacherStudentsPostPath = '/api/Teacher/GetTeacherStudents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherStudentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherStudentsPost$Plain$Response(params?: ApiTeacherGetTeacherStudentsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherStudentsQueryResult>> {
    return apiTeacherGetTeacherStudentsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherStudentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherStudentsPost$Plain(params?: ApiTeacherGetTeacherStudentsPost$Plain$Params, context?: HttpContext): Observable<GetTeacherStudentsQueryResult> {
    return this.apiTeacherGetTeacherStudentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherStudentsQueryResult>): GetTeacherStudentsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherStudentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherStudentsPost$Response(params?: ApiTeacherGetTeacherStudentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherStudentsQueryResult>> {
    return apiTeacherGetTeacherStudentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherStudentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherGetTeacherStudentsPost(params?: ApiTeacherGetTeacherStudentsPost$Params, context?: HttpContext): Observable<GetTeacherStudentsQueryResult> {
    return this.apiTeacherGetTeacherStudentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherStudentsQueryResult>): GetTeacherStudentsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherAddStudentToCoursePost()` */
  static readonly ApiTeacherAddStudentToCoursePostPath = '/api/Teacher/AddStudentToCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherAddStudentToCoursePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddStudentToCoursePost$Plain$Response(params?: ApiTeacherAddStudentToCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AddStudentToCourseCommandResult>> {
    return apiTeacherAddStudentToCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherAddStudentToCoursePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddStudentToCoursePost$Plain(params?: ApiTeacherAddStudentToCoursePost$Plain$Params, context?: HttpContext): Observable<AddStudentToCourseCommandResult> {
    return this.apiTeacherAddStudentToCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddStudentToCourseCommandResult>): AddStudentToCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherAddStudentToCoursePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddStudentToCoursePost$Response(params?: ApiTeacherAddStudentToCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<AddStudentToCourseCommandResult>> {
    return apiTeacherAddStudentToCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherAddStudentToCoursePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherAddStudentToCoursePost(params?: ApiTeacherAddStudentToCoursePost$Params, context?: HttpContext): Observable<AddStudentToCourseCommandResult> {
    return this.apiTeacherAddStudentToCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddStudentToCourseCommandResult>): AddStudentToCourseCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherCreateTeacherPost()` */
  static readonly ApiTeacherCreateTeacherPostPath = '/api/Teacher/CreateTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherCreateTeacherPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherCreateTeacherPost$Plain$Response(params?: ApiTeacherCreateTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTeacherCommandResult>> {
    return apiTeacherCreateTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherCreateTeacherPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherCreateTeacherPost$Plain(params?: ApiTeacherCreateTeacherPost$Plain$Params, context?: HttpContext): Observable<CreateTeacherCommandResult> {
    return this.apiTeacherCreateTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTeacherCommandResult>): CreateTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherCreateTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherCreateTeacherPost$Response(params?: ApiTeacherCreateTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTeacherCommandResult>> {
    return apiTeacherCreateTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherCreateTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherCreateTeacherPost(params?: ApiTeacherCreateTeacherPost$Params, context?: HttpContext): Observable<CreateTeacherCommandResult> {
    return this.apiTeacherCreateTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTeacherCommandResult>): CreateTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherUpdateTeacherPost()` */
  static readonly ApiTeacherUpdateTeacherPostPath = '/api/Teacher/UpdateTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateTeacherPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateTeacherPost$Plain$Response(params?: ApiTeacherUpdateTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTeacherCommandResult>> {
    return apiTeacherUpdateTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateTeacherPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateTeacherPost$Plain(params?: ApiTeacherUpdateTeacherPost$Plain$Params, context?: HttpContext): Observable<UpdateTeacherCommandResult> {
    return this.apiTeacherUpdateTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTeacherCommandResult>): UpdateTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateTeacherPost$Response(params?: ApiTeacherUpdateTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTeacherCommandResult>> {
    return apiTeacherUpdateTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateTeacherPost(params?: ApiTeacherUpdateTeacherPost$Params, context?: HttpContext): Observable<UpdateTeacherCommandResult> {
    return this.apiTeacherUpdateTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTeacherCommandResult>): UpdateTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherUpdateCurrentTeacherPost()` */
  static readonly ApiTeacherUpdateCurrentTeacherPostPath = '/api/Teacher/UpdateCurrentTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateCurrentTeacherPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCurrentTeacherPost$Plain$Response(params?: ApiTeacherUpdateCurrentTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCurrentTeacherCommandResult>> {
    return apiTeacherUpdateCurrentTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateCurrentTeacherPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCurrentTeacherPost$Plain(params?: ApiTeacherUpdateCurrentTeacherPost$Plain$Params, context?: HttpContext): Observable<UpdateCurrentTeacherCommandResult> {
    return this.apiTeacherUpdateCurrentTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCurrentTeacherCommandResult>): UpdateCurrentTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateCurrentTeacherPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCurrentTeacherPost$Response(params?: ApiTeacherUpdateCurrentTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCurrentTeacherCommandResult>> {
    return apiTeacherUpdateCurrentTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateCurrentTeacherPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCurrentTeacherPost(params?: ApiTeacherUpdateCurrentTeacherPost$Params, context?: HttpContext): Observable<UpdateCurrentTeacherCommandResult> {
    return this.apiTeacherUpdateCurrentTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCurrentTeacherCommandResult>): UpdateCurrentTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherDeleteTeacherDelete()` */
  static readonly ApiTeacherDeleteTeacherDeletePath = '/api/Teacher/DeleteTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherDeleteTeacherDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteTeacherDelete$Plain$Response(params?: ApiTeacherDeleteTeacherDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTeacherCommandResult>> {
    return apiTeacherDeleteTeacherDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherDeleteTeacherDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteTeacherDelete$Plain(params?: ApiTeacherDeleteTeacherDelete$Plain$Params, context?: HttpContext): Observable<DeleteTeacherCommandResult> {
    return this.apiTeacherDeleteTeacherDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTeacherCommandResult>): DeleteTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherDeleteTeacherDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteTeacherDelete$Response(params?: ApiTeacherDeleteTeacherDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTeacherCommandResult>> {
    return apiTeacherDeleteTeacherDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherDeleteTeacherDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteTeacherDelete(params?: ApiTeacherDeleteTeacherDelete$Params, context?: HttpContext): Observable<DeleteTeacherCommandResult> {
    return this.apiTeacherDeleteTeacherDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTeacherCommandResult>): DeleteTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherDeleteCourseTeacherDelete()` */
  static readonly ApiTeacherDeleteCourseTeacherDeletePath = '/api/Teacher/DeleteCourseTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherDeleteCourseTeacherDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteCourseTeacherDelete$Plain$Response(params?: ApiTeacherDeleteCourseTeacherDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCourseTeacherCommandResult>> {
    return apiTeacherDeleteCourseTeacherDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherDeleteCourseTeacherDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteCourseTeacherDelete$Plain(params?: ApiTeacherDeleteCourseTeacherDelete$Plain$Params, context?: HttpContext): Observable<DeleteCourseTeacherCommandResult> {
    return this.apiTeacherDeleteCourseTeacherDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCourseTeacherCommandResult>): DeleteCourseTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherDeleteCourseTeacherDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteCourseTeacherDelete$Response(params?: ApiTeacherDeleteCourseTeacherDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCourseTeacherCommandResult>> {
    return apiTeacherDeleteCourseTeacherDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherDeleteCourseTeacherDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherDeleteCourseTeacherDelete(params?: ApiTeacherDeleteCourseTeacherDelete$Params, context?: HttpContext): Observable<DeleteCourseTeacherCommandResult> {
    return this.apiTeacherDeleteCourseTeacherDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCourseTeacherCommandResult>): DeleteCourseTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherFreezeOrUnFreezeCourseTeacherPost()` */
  static readonly ApiTeacherFreezeOrUnFreezeCourseTeacherPostPath = '/api/Teacher/FreezeOrUnFreezeCourseTeacher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Response(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FreezeCourseTeacherCommandResult>> {
    return apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Params, context?: HttpContext): Observable<FreezeCourseTeacherCommandResult> {
    return this.apiTeacherFreezeOrUnFreezeCourseTeacherPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FreezeCourseTeacherCommandResult>): FreezeCourseTeacherCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherFreezeOrUnFreezeCourseTeacherPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherPost$Response(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Params, context?: HttpContext): Observable<StrictHttpResponse<FreezeCourseTeacherCommandResult>> {
    return apiTeacherFreezeOrUnFreezeCourseTeacherPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherFreezeOrUnFreezeCourseTeacherPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherPost(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherPost$Params, context?: HttpContext): Observable<FreezeCourseTeacherCommandResult> {
    return this.apiTeacherFreezeOrUnFreezeCourseTeacherPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FreezeCourseTeacherCommandResult>): FreezeCourseTeacherCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost()` */
  static readonly ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPostPath = '/api/Teacher/FreezeOrUnFreezeCourseTeacherStudent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Response(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FreezeStudentFromCourseCommandResult>> {
    return apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Params, context?: HttpContext): Observable<FreezeStudentFromCourseCommandResult> {
    return this.apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FreezeStudentFromCourseCommandResult>): FreezeStudentFromCourseCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Response(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<FreezeStudentFromCourseCommandResult>> {
    return apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost(params?: ApiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Params, context?: HttpContext): Observable<FreezeStudentFromCourseCommandResult> {
    return this.apiTeacherFreezeOrUnFreezeCourseTeacherStudentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FreezeStudentFromCourseCommandResult>): FreezeStudentFromCourseCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherUpdateCourseTeacherImagePost()` */
  static readonly ApiTeacherUpdateCourseTeacherImagePostPath = '/api/Teacher/UpdateCourseTeacherImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateCourseTeacherImagePost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCourseTeacherImagePost$Plain$Response(params?: ApiTeacherUpdateCourseTeacherImagePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCourseTeacherImageCommandResult>> {
    return apiTeacherUpdateCourseTeacherImagePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateCourseTeacherImagePost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCourseTeacherImagePost$Plain(params?: ApiTeacherUpdateCourseTeacherImagePost$Plain$Params, context?: HttpContext): Observable<UpdateCourseTeacherImageCommandResult> {
    return this.apiTeacherUpdateCourseTeacherImagePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCourseTeacherImageCommandResult>): UpdateCourseTeacherImageCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherUpdateCourseTeacherImagePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCourseTeacherImagePost$Response(params?: ApiTeacherUpdateCourseTeacherImagePost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCourseTeacherImageCommandResult>> {
    return apiTeacherUpdateCourseTeacherImagePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherUpdateCourseTeacherImagePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherUpdateCourseTeacherImagePost(params?: ApiTeacherUpdateCourseTeacherImagePost$Params, context?: HttpContext): Observable<UpdateCourseTeacherImageCommandResult> {
    return this.apiTeacherUpdateCourseTeacherImagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCourseTeacherImageCommandResult>): UpdateCourseTeacherImageCommandResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetTeacherDashboardPost()` */
  static readonly ApiTeacherGetTeacherDashboardPostPath = '/api/Teacher/GetTeacherDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherDashboardPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetTeacherDashboardPost$Plain$Response(params?: ApiTeacherGetTeacherDashboardPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherDashboardQueryResult>> {
    return apiTeacherGetTeacherDashboardPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherDashboardPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetTeacherDashboardPost$Plain(params?: ApiTeacherGetTeacherDashboardPost$Plain$Params, context?: HttpContext): Observable<GetTeacherDashboardQueryResult> {
    return this.apiTeacherGetTeacherDashboardPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherDashboardQueryResult>): GetTeacherDashboardQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetTeacherDashboardPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetTeacherDashboardPost$Response(params?: ApiTeacherGetTeacherDashboardPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherDashboardQueryResult>> {
    return apiTeacherGetTeacherDashboardPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetTeacherDashboardPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetTeacherDashboardPost(params?: ApiTeacherGetTeacherDashboardPost$Params, context?: HttpContext): Observable<GetTeacherDashboardQueryResult> {
    return this.apiTeacherGetTeacherDashboardPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherDashboardQueryResult>): GetTeacherDashboardQueryResult => r.body)
    );
  }

  /** Path part for operation `apiTeacherGetRewardWallPost()` */
  static readonly ApiTeacherGetRewardWallPostPath = '/api/Teacher/GetRewardWall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetRewardWallPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetRewardWallPost$Plain$Response(params?: ApiTeacherGetRewardWallPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRewardWallQueryResult>> {
    return apiTeacherGetRewardWallPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetRewardWallPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetRewardWallPost$Plain(params?: ApiTeacherGetRewardWallPost$Plain$Params, context?: HttpContext): Observable<GetRewardWallQueryResult> {
    return this.apiTeacherGetRewardWallPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRewardWallQueryResult>): GetRewardWallQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTeacherGetRewardWallPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetRewardWallPost$Response(params?: ApiTeacherGetRewardWallPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRewardWallQueryResult>> {
    return apiTeacherGetRewardWallPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTeacherGetRewardWallPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiTeacherGetRewardWallPost(params?: ApiTeacherGetRewardWallPost$Params, context?: HttpContext): Observable<GetRewardWallQueryResult> {
    return this.apiTeacherGetRewardWallPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRewardWallQueryResult>): GetRewardWallQueryResult => r.body)
    );
  }

}
