import { Observable } from 'rxjs';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';




export const projectName = {
  en: 'المفيد',
  ar: 'المفيد'
};



export const ROLES = {
  Student: 'student',
  Teacher: 'teacher',
  Admin: 'admin',
}

export const logo = {
  light: '/assets/images/logo-light.svg',
  dark: '/assets/images/logo-dark.svg',
  light_sm: '/assets/images/logo-sm-light.svg',
  dark_sm: '/assets/images/logo-sm-dark.svg',

}


export function SWAL_Celebrate(title?: string | null, text?: string | null, iconHtml?: string | null, backdrop?: string | null, confirmButtonText?: string) {
  return Swal.fire({
    title: title || "كفووو",
    text: text || "أنهيت الدرس بنجاح",
    icon: 'success',
    iconHtml: iconHtml || '<img height="100" src="/assets/images/prize_1.png"/>',
    backdrop: backdrop || `
    rgba(0,0,0,0.2)
    url("/assets/images/celebration.gif")
    center top
    repeat
  `,
    timer: 3000,
    timerProgressBar: true,
    confirmButtonText: confirmButtonText || 'تمام'
  });
}


export function extractFileNameFromContentDispositionHeader(value) {
  let patterns = [
    /filename\*=[^']+'\w*'"([^"]+)";?/i,
    /filename\*=[^']+'\w*'([^;]+);?/i,
    /filename="([^;]*);?"/i,
    /filename=([^;]*);?/i
  ]

  let responseFilename
  patterns.some(regex => {
    responseFilename = regex.exec(value)
    return responseFilename !== null
  })

  if (responseFilename !== null && responseFilename.length > 1) {
    try {
      return decodeURIComponent(responseFilename[1])
    } catch (e) {
      console.error(e)
    }
  }

  return 'file.xlsx'
}
export function downloadData(data: BlobPart, contentDispositionHeader: string, contentType: string | null) {
  if (data && contentDispositionHeader && contentType) {
    try {
      let filename = extractFileNameFromContentDispositionHeader(contentDispositionHeader);
      if (!filename) {
        console.error('Failed to extract filename from content disposition header');
        return;
      }

      const blob = (data instanceof Blob) ? data : new Blob([data], { type: contentType || 'application/octet-stream' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      // Clean up the URL and link element
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
}


export function isSafeCoordinate(number: unknown): number | undefined {
  return number != '' && number != null && number != undefined ? +number : undefined
}
export const responseErrors = {
  DuplicateName: 'إسم مكرر مسبقًا',
  Error: 'حدث خطأ',
  CompanyTypeNotEqualsProjectType: 'الشركة ونوع المشروع غير متطابقين',
  LOCKED: 'تم إيقاف حسابك مؤقتًا .. برجاء التواصل مع مدير النظام',
  'The Name field is required.': 'يجب إدخــال الإسم',
  'The projectCommand field is required.': 'بعض المدخلات الإجبارية غير موجودة',
  FieldRequired: 'برجاء ملء كل البيانات بشكل صحيح',
  InvalidCRN: 'رقم سجل تجاري غير صحيح',
  MaxFieldLength: 'عدد الحروف أكثر من المطلوب',
  MinFieldLength: 'عدد الحروف أقل من المطلوب',
  UserNotFound: 'المستخدم غير موجود',
  InvalidLogin: 'بيانات الدخول غير صحيحة',
  DuplicateUser: 'المستخدم موجود من قبل',
  DuplicateEmail: 'تم تسجيل حساب بالفعل بهذا البريد الإلكتروني',
  DuplicatePhoneNumber: 'هناك حساب بالفعل يستخدم نفس رقم التليفون',
  NotFound: 'لا يوجد بيانات',
  CantDelete: 'لا يمكن الحــذف',
  DuplicateCode: 'الكود مُستخدم من قبــل',
  UserAlreadyLoggedIn: 'أنت بالفعل مسجل دخولك',
  InvalidEmailAddress: 'البريد الإلكتروني غير صحيح',
  InvalidPhoneNumber: 'رقم التليفون غير صحيح',
  Expired: 'انتهت صلاحية رمز التاكيد',
  CantLockYourself: 'لا يمكنك إيقاف حسابك',

  DuplicateCrn: 'رقم تجاري مكرر',
  NotEnoughAnswers: 'لا يوجد إجابات كفاية',
  DuplicateCourse: 'المنهج موجود بالفعل',
  AccessDenied: 'لا تمتلك صلاحية الوصول',
  CantCheckAnswer: 'لا يمكن التحقق من الجواب',
  WrongAnswer: 'إجابة خاطئة',
  InvalidUsername: 'اسم مستخدم غير مقبول',
  InvalidCrn: 'رقم تجاري غير صالح',
  InvalidPasswordRequirements: 'كلمة مرور غير صالحة',
  DuplicateIdNumber: 'Id مكرر',
  InvalidStartDate: 'تاريخ بداية غير صالح',
  InvalidEndDate: 'تاريخ نهاية غير صالح',
  InvalidDate: 'تاريخ غير صالح',
  CantLockAdmin: 'لا يمكن قفل حساب المسؤول',
  NotStudent: 'هذا المستخدم ليس طالبًا',
  Freezed: 'هذا الحساب موقوف',

  DidnotSubscribe: 'لم يتم الإشتراك في المنهج',
  CantAddTask: 'لا يمكن إضافة مهمة',
  CompleteLatestTask: 'يجب إنهاء المهمة الحالية قبل إنشاء مهمة أخرى',
  InvalidToken: 'رمز التحقق غير صالح',
  StudentAlreadyJoin: 'تم الإنضمام للمنهج من قبل',
  CourseFreezed: 'هذا المنهج موقوف حاليًا ',
  StudentFreezed: 'هذا الطالب موقوف حاليًا',
  AlreadyEmailConfirmed: 'البريد الإلكتروني مؤكد بالفعل .. حاول تسجيل الدخول',
  CantSendEmail: 'خطأ في إرسال بريد التأكيد برجاء التواصل مع الدعم الفني',
  CantChangeEmail: 'لا يمكن تغيير البريد',
  NotActiveForLongTime: 'أنت لم تقم بتسجيل الدخول منذ فترة وتم إيقاف حسابك، تواصل مع الدعم لإعادة تنشيطه',
  AccountNotHaveValidity: 'انتهت صلاحية حسابك، تواصل مع الدعم الفني لإعادة تنشيطه',
  SubscriptionAlreadySent: "تم إرسال طلب اشتراك بالفعل"

}


export const arabicArray = ["أ", "ب", "جـ", "د", "هـ", "و", "ز", "ح", "ط", "ي"];

export const errorCallback = (err: any) => { SWAL('error', err?.errorCode) }

export const SWAL = (type: SweetAlertIcon, title: string, text?: string
  ,
  options?: SweetAlertOptions, attachSupportLink?: boolean): Promise<SweetAlertResult<any>> | null => {
  if (title === 'NO-ERROR') {
    return null;
  }
  let errmsg = title ? responseErrors[title] || title : title;

  return Swal.fire({
    icon: type,
    titleText: errmsg || (type === 'error' ? 'حدث خطأ' : ''),
    text,
    html: attachSupportLink ?
      `
    <div class="text-center mt-3">
    <a href="https://wa.me/966538720990" target="_blank" referrerpolicy="no-referrer"
      class="d-block text-decoration-underline text-gray-800 text-center mt-3">
      <span class="bx bxl-whatsapp align-middle"></span>
      يمكنك التواصل مع الدعم الفني
    </a>
  </div>
    ` : undefined,
    confirmButtonColor: '#2BA18Cff',
    confirmButtonText: 'تمام',
    cancelButtonText: 'إلغاء',
    ...options,

  })
}
export const SWALConfirmation = (
  type: SweetAlertIcon,
  title?: string,
  callableFunction?: Observable<any>,
  resultText?: string,
  actionText?: string,

): Promise<SweetAlertResult<boolean>> => {

  return Swal.fire({
    title: title,
    icon: type,
    showCancelButton: true,
    confirmButtonText: actionText || 'نعـم',
    cancelButtonText: 'إغلاق',
    showLoaderOnConfirm: true,
    confirmButtonColor: '#f46a6a',
    preConfirm: isConfirmed => {
      // eslint-disable-next-line no-unused-vars
      return new Promise<boolean>((resolve, reject) => {
        if (isConfirmed) {
          callableFunction?.subscribe({
            next: (res) => {
              SWAL('success', resultText || 'تم التنفيذ');
              resolve(true)
            },
            error: (res) => {
              SWAL('error', res?.errorCode);
              reject()
            }
          })
        }
      });
    },
    allowOutsideClick: true
  })

}



export const userNamePattern = '^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'
export const numberMoreThanZeroPattern = /^[1-9]\d*(\.\d+)?$/
export const mustNumberPattern = /^-?\d*(\.\d+)?$/
export const mobilePhonePattern = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
