import { ROLES } from 'src/app/app-const';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  // ?? Admin
  {
    id: 2,
    label: 'الرئيسية',
    icon: 'isax-category ',
    link: '/admin',
    roles: [ROLES.Admin],
    exact: true,
  },
  {
    id: 2,
    label: 'طلبات الإنضمام',
    icon: 'isax-notification-bing1 ',
    link: '/admin/subscribe-requests',
    roles: [ROLES.Admin],
  },
  {
    id: 2,
    label: 'إدارة المعلمين',
    icon: 'isax-profile-2user ',
    link: '/admin/teacher-management',
    roles: [ROLES.Admin],
  },
  {
    id: 2,
    label: ' مناهج المعلمين',
    icon: 'isax-category-2 ',
    link: '/admin/teacher-courses-management',
    roles: [ROLES.Admin],
  },
  {
    id: 2,
    label: 'إدارة الطلاب',
    icon: 'isax-people ',
    link: '/admin/student-management',
    roles: [ROLES.Admin],
  },
  
  {
    id: 2,
    label: 'أقسام المناهج ',
    icon: 'isax-document-text ',
    link: '/admin/subject-management',
    roles: [ROLES.Admin],
  },
  {
    id: 87,
    label: 'الآراء والمقترحات',
    icon: 'isax-message-favorite',
    link: '/admin/feedbacks',
    roles: [ROLES.Admin],
  },
  // {
  //   id: 2,
  //   label: 'الإعدادات',
  //   icon: 'isax-setting-2',
  //   link: '/admin/setting',
  //   roles: [ROLES.Admin],
  // },
  // ?? student
  {
    id: 3,
    label: 'الرئيسية',
    icon: 'isax-category ',
    link: '/student',
    roles: [ROLES.Student],
    exact: true,
  },
  {
    id: 99,
    label: 'كورساتي',
    icon: 'isax-video-square ',
    link: '/student/my-courses',
    roles: [ROLES.Student],
  },
  {
    id: 99,
    label: ' بنوك الأسئلــة',
    icon: 'isax-task-square ',
    link: '/student/course-bank',
    roles: [ROLES.Student],
  },
  {
    isTitle: true,
    label: 'تصفح المزيد',
    roles: [ROLES.Student],
  },
  {
    id: 3,
    label: ' مناهج المنصة',
    icon: 'isax-document-text ',
    link: '/student/all-courses',
    roles: [ROLES.Student],
  },
  {
    id: 3,
    label: ' معلمو المنصة',
    icon: 'isax-user-search ',
    link: '/student/all-teachers',
    roles: [ROLES.Student],
  },
  {
    id: 3,
    label: ' الإعدادات',
    icon: 'isax-setting-2 ',
    link: '/student/settings',
    roles: [ROLES.Student],
  },
  // ???Teacher
  {
    id: 3,
    label: 'الرئيسية',
    icon: 'isax-category ',
    link: '/teacher',
    roles: [ROLES.Teacher],
    exact: true,
  },
  {
    id: 2,
    label: 'طلبات الإنضمام',
    icon: 'isax-notification-bing1',
    link: '/teacher/subscribe-requests',
    roles: [ROLES.Teacher],
  },
  {
    id: 99,
    label: ' المناهج الخاصة بي',
    icon: 'isax-video-square ',
    link: '/teacher/my-courses',
    roles: [ROLES.Teacher],
  },
  {
    id: 2,
    label: 'طلابــك',
    icon: 'isax-people ',
    link: '/teacher/student-management',
    roles: [ROLES.Teacher],
  },
  {
    id: 3,
    label: ' البيانات الشخصية',
    icon: 'isax-user ',
    link: '/teacher/settings',
    roles: [ROLES.Teacher],
  },

];
