/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiPublicDataGetCoursesForTeacherPublicByUsernamePost } from '../fn/public-data/api-public-data-get-courses-for-teacher-public-by-username-post';
import { ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Params } from '../fn/public-data/api-public-data-get-courses-for-teacher-public-by-username-post';
import { apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain } from '../fn/public-data/api-public-data-get-courses-for-teacher-public-by-username-post-plain';
import { ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Params } from '../fn/public-data/api-public-data-get-courses-for-teacher-public-by-username-post-plain';
import { apiPublicDataGetCoursesTeacherPublicPost } from '../fn/public-data/api-public-data-get-courses-teacher-public-post';
import { ApiPublicDataGetCoursesTeacherPublicPost$Params } from '../fn/public-data/api-public-data-get-courses-teacher-public-post';
import { apiPublicDataGetCoursesTeacherPublicPost$Plain } from '../fn/public-data/api-public-data-get-courses-teacher-public-post-plain';
import { ApiPublicDataGetCoursesTeacherPublicPost$Plain$Params } from '../fn/public-data/api-public-data-get-courses-teacher-public-post-plain';
import { apiPublicDataGetCourseTypesPost } from '../fn/public-data/api-public-data-get-course-types-post';
import { ApiPublicDataGetCourseTypesPost$Params } from '../fn/public-data/api-public-data-get-course-types-post';
import { apiPublicDataGetCourseTypesPost$Plain } from '../fn/public-data/api-public-data-get-course-types-post-plain';
import { ApiPublicDataGetCourseTypesPost$Plain$Params } from '../fn/public-data/api-public-data-get-course-types-post-plain';
import { apiPublicDataGetPlatformUpdatesGet } from '../fn/public-data/api-public-data-get-platform-updates-get';
import { ApiPublicDataGetPlatformUpdatesGet$Params } from '../fn/public-data/api-public-data-get-platform-updates-get';
import { apiPublicDataGetPlatformUpdatesGet$Plain } from '../fn/public-data/api-public-data-get-platform-updates-get-plain';
import { ApiPublicDataGetPlatformUpdatesGet$Plain$Params } from '../fn/public-data/api-public-data-get-platform-updates-get-plain';
import { apiPublicDataGetTeacherByUsernamePost } from '../fn/public-data/api-public-data-get-teacher-by-username-post';
import { ApiPublicDataGetTeacherByUsernamePost$Params } from '../fn/public-data/api-public-data-get-teacher-by-username-post';
import { apiPublicDataGetTeacherByUsernamePost$Plain } from '../fn/public-data/api-public-data-get-teacher-by-username-post-plain';
import { ApiPublicDataGetTeacherByUsernamePost$Plain$Params } from '../fn/public-data/api-public-data-get-teacher-by-username-post-plain';
import { apiPublicDataGetTeachersPublicPost } from '../fn/public-data/api-public-data-get-teachers-public-post';
import { ApiPublicDataGetTeachersPublicPost$Params } from '../fn/public-data/api-public-data-get-teachers-public-post';
import { apiPublicDataGetTeachersPublicPost$Plain } from '../fn/public-data/api-public-data-get-teachers-public-post-plain';
import { ApiPublicDataGetTeachersPublicPost$Plain$Params } from '../fn/public-data/api-public-data-get-teachers-public-post-plain';
import { apiPublicDataSubscribeToCoursePost } from '../fn/public-data/api-public-data-subscribe-to-course-post';
import { ApiPublicDataSubscribeToCoursePost$Params } from '../fn/public-data/api-public-data-subscribe-to-course-post';
import { apiPublicDataSubscribeToCoursePost$Plain } from '../fn/public-data/api-public-data-subscribe-to-course-post-plain';
import { ApiPublicDataSubscribeToCoursePost$Plain$Params } from '../fn/public-data/api-public-data-subscribe-to-course-post-plain';
import { CreateRequestCommandResult } from '../models/create-request-command-result';
import { GetCoursesForTeacherPublicQueryResult } from '../models/get-courses-for-teacher-public-query-result';
import { GetCourseTypesQueryResult } from '../models/get-course-types-query-result';
import { GetPublicCoursesQueryResult } from '../models/get-public-courses-query-result';
import { GetTeacherByUsernameQueryResult } from '../models/get-teacher-by-username-query-result';
import { GetTeachersPublicQueryResult } from '../models/get-teachers-public-query-result';
import { PlatformUpdate } from '../models/platform-update';

@Injectable({ providedIn: 'root' })
export class PublicDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPublicDataGetTeachersPublicPost()` */
  static readonly ApiPublicDataGetTeachersPublicPostPath = '/api/PublicData/GetTeachersPublic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetTeachersPublicPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeachersPublicPost$Plain$Response(params?: ApiPublicDataGetTeachersPublicPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeachersPublicQueryResult>> {
    return apiPublicDataGetTeachersPublicPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetTeachersPublicPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeachersPublicPost$Plain(params?: ApiPublicDataGetTeachersPublicPost$Plain$Params, context?: HttpContext): Observable<GetTeachersPublicQueryResult> {
    return this.apiPublicDataGetTeachersPublicPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeachersPublicQueryResult>): GetTeachersPublicQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetTeachersPublicPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeachersPublicPost$Response(params?: ApiPublicDataGetTeachersPublicPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeachersPublicQueryResult>> {
    return apiPublicDataGetTeachersPublicPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetTeachersPublicPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeachersPublicPost(params?: ApiPublicDataGetTeachersPublicPost$Params, context?: HttpContext): Observable<GetTeachersPublicQueryResult> {
    return this.apiPublicDataGetTeachersPublicPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeachersPublicQueryResult>): GetTeachersPublicQueryResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataGetTeacherByUsernamePost()` */
  static readonly ApiPublicDataGetTeacherByUsernamePostPath = '/api/PublicData/GetTeacherByUsername';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetTeacherByUsernamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeacherByUsernamePost$Plain$Response(params?: ApiPublicDataGetTeacherByUsernamePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherByUsernameQueryResult>> {
    return apiPublicDataGetTeacherByUsernamePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetTeacherByUsernamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeacherByUsernamePost$Plain(params?: ApiPublicDataGetTeacherByUsernamePost$Plain$Params, context?: HttpContext): Observable<GetTeacherByUsernameQueryResult> {
    return this.apiPublicDataGetTeacherByUsernamePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherByUsernameQueryResult>): GetTeacherByUsernameQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetTeacherByUsernamePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeacherByUsernamePost$Response(params?: ApiPublicDataGetTeacherByUsernamePost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherByUsernameQueryResult>> {
    return apiPublicDataGetTeacherByUsernamePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetTeacherByUsernamePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetTeacherByUsernamePost(params?: ApiPublicDataGetTeacherByUsernamePost$Params, context?: HttpContext): Observable<GetTeacherByUsernameQueryResult> {
    return this.apiPublicDataGetTeacherByUsernamePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherByUsernameQueryResult>): GetTeacherByUsernameQueryResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataGetCourseTypesPost()` */
  static readonly ApiPublicDataGetCourseTypesPostPath = '/api/PublicData/GetCourseTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCourseTypesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCourseTypesPost$Plain$Response(params?: ApiPublicDataGetCourseTypesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTypesQueryResult>> {
    return apiPublicDataGetCourseTypesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCourseTypesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCourseTypesPost$Plain(params?: ApiPublicDataGetCourseTypesPost$Plain$Params, context?: HttpContext): Observable<GetCourseTypesQueryResult> {
    return this.apiPublicDataGetCourseTypesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTypesQueryResult>): GetCourseTypesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCourseTypesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCourseTypesPost$Response(params?: ApiPublicDataGetCourseTypesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTypesQueryResult>> {
    return apiPublicDataGetCourseTypesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCourseTypesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCourseTypesPost(params?: ApiPublicDataGetCourseTypesPost$Params, context?: HttpContext): Observable<GetCourseTypesQueryResult> {
    return this.apiPublicDataGetCourseTypesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTypesQueryResult>): GetCourseTypesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataGetCoursesForTeacherPublicByUsernamePost()` */
  static readonly ApiPublicDataGetCoursesForTeacherPublicByUsernamePostPath = '/api/PublicData/GetCoursesForTeacherPublicByUsername';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Response(params?: ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCoursesForTeacherPublicQueryResult>> {
    return apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain(params?: ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Params, context?: HttpContext): Observable<GetCoursesForTeacherPublicQueryResult> {
    return this.apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCoursesForTeacherPublicQueryResult>): GetCoursesForTeacherPublicQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCoursesForTeacherPublicByUsernamePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Response(params?: ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCoursesForTeacherPublicQueryResult>> {
    return apiPublicDataGetCoursesForTeacherPublicByUsernamePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesForTeacherPublicByUsernamePost(params?: ApiPublicDataGetCoursesForTeacherPublicByUsernamePost$Params, context?: HttpContext): Observable<GetCoursesForTeacherPublicQueryResult> {
    return this.apiPublicDataGetCoursesForTeacherPublicByUsernamePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCoursesForTeacherPublicQueryResult>): GetCoursesForTeacherPublicQueryResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataGetCoursesTeacherPublicPost()` */
  static readonly ApiPublicDataGetCoursesTeacherPublicPostPath = '/api/PublicData/GetCoursesTeacherPublic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCoursesTeacherPublicPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesTeacherPublicPost$Plain$Response(params?: ApiPublicDataGetCoursesTeacherPublicPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPublicCoursesQueryResult>> {
    return apiPublicDataGetCoursesTeacherPublicPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCoursesTeacherPublicPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesTeacherPublicPost$Plain(params?: ApiPublicDataGetCoursesTeacherPublicPost$Plain$Params, context?: HttpContext): Observable<GetPublicCoursesQueryResult> {
    return this.apiPublicDataGetCoursesTeacherPublicPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPublicCoursesQueryResult>): GetPublicCoursesQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetCoursesTeacherPublicPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesTeacherPublicPost$Response(params?: ApiPublicDataGetCoursesTeacherPublicPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPublicCoursesQueryResult>> {
    return apiPublicDataGetCoursesTeacherPublicPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetCoursesTeacherPublicPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataGetCoursesTeacherPublicPost(params?: ApiPublicDataGetCoursesTeacherPublicPost$Params, context?: HttpContext): Observable<GetPublicCoursesQueryResult> {
    return this.apiPublicDataGetCoursesTeacherPublicPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPublicCoursesQueryResult>): GetPublicCoursesQueryResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataSubscribeToCoursePost()` */
  static readonly ApiPublicDataSubscribeToCoursePostPath = '/api/PublicData/SubscribeToCourse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataSubscribeToCoursePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataSubscribeToCoursePost$Plain$Response(params?: ApiPublicDataSubscribeToCoursePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRequestCommandResult>> {
    return apiPublicDataSubscribeToCoursePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataSubscribeToCoursePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataSubscribeToCoursePost$Plain(params?: ApiPublicDataSubscribeToCoursePost$Plain$Params, context?: HttpContext): Observable<CreateRequestCommandResult> {
    return this.apiPublicDataSubscribeToCoursePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRequestCommandResult>): CreateRequestCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataSubscribeToCoursePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataSubscribeToCoursePost$Response(params?: ApiPublicDataSubscribeToCoursePost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRequestCommandResult>> {
    return apiPublicDataSubscribeToCoursePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataSubscribeToCoursePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPublicDataSubscribeToCoursePost(params?: ApiPublicDataSubscribeToCoursePost$Params, context?: HttpContext): Observable<CreateRequestCommandResult> {
    return this.apiPublicDataSubscribeToCoursePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRequestCommandResult>): CreateRequestCommandResult => r.body)
    );
  }

  /** Path part for operation `apiPublicDataGetPlatformUpdatesGet()` */
  static readonly ApiPublicDataGetPlatformUpdatesGetPath = '/api/PublicData/GetPlatformUpdates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetPlatformUpdatesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicDataGetPlatformUpdatesGet$Plain$Response(params?: ApiPublicDataGetPlatformUpdatesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlatformUpdate>>> {
    return apiPublicDataGetPlatformUpdatesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetPlatformUpdatesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicDataGetPlatformUpdatesGet$Plain(params?: ApiPublicDataGetPlatformUpdatesGet$Plain$Params, context?: HttpContext): Observable<Array<PlatformUpdate>> {
    return this.apiPublicDataGetPlatformUpdatesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlatformUpdate>>): Array<PlatformUpdate> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicDataGetPlatformUpdatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicDataGetPlatformUpdatesGet$Response(params?: ApiPublicDataGetPlatformUpdatesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PlatformUpdate>>> {
    return apiPublicDataGetPlatformUpdatesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicDataGetPlatformUpdatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicDataGetPlatformUpdatesGet(params?: ApiPublicDataGetPlatformUpdatesGet$Params, context?: HttpContext): Observable<Array<PlatformUpdate>> {
    return this.apiPublicDataGetPlatformUpdatesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PlatformUpdate>>): Array<PlatformUpdate> => r.body)
    );
  }

}
