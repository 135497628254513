<footer class="w-100 bg-EAF0FF py-2">
    <div class="container-fluid small">
        <div class="text-center">
            {{year}} © {{projectName | translate}}، 
            تم التطوير بواسطة
            <a href="https://wa.me/201092376044?text=Hello%20Magdy" target="_blank" rel="noopener noreferrer">
                مجدي الصياد
            </a>
           
        </div>
  
    </div>
</footer>