/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiQuestionBankCreateBankPost } from '../fn/question-bank/api-question-bank-create-bank-post';
import { ApiQuestionBankCreateBankPost$Params } from '../fn/question-bank/api-question-bank-create-bank-post';
import { apiQuestionBankCreateBankPost$Plain } from '../fn/question-bank/api-question-bank-create-bank-post-plain';
import { ApiQuestionBankCreateBankPost$Plain$Params } from '../fn/question-bank/api-question-bank-create-bank-post-plain';
import { apiQuestionBankDeleteBankPost } from '../fn/question-bank/api-question-bank-delete-bank-post';
import { ApiQuestionBankDeleteBankPost$Params } from '../fn/question-bank/api-question-bank-delete-bank-post';
import { apiQuestionBankDeleteBankPost$Plain } from '../fn/question-bank/api-question-bank-delete-bank-post-plain';
import { ApiQuestionBankDeleteBankPost$Plain$Params } from '../fn/question-bank/api-question-bank-delete-bank-post-plain';
import { apiQuestionBankGetBankPost } from '../fn/question-bank/api-question-bank-get-bank-post';
import { ApiQuestionBankGetBankPost$Params } from '../fn/question-bank/api-question-bank-get-bank-post';
import { apiQuestionBankGetBankPost$Plain } from '../fn/question-bank/api-question-bank-get-bank-post-plain';
import { ApiQuestionBankGetBankPost$Plain$Params } from '../fn/question-bank/api-question-bank-get-bank-post-plain';
import { apiQuestionBankGetTasksDataPost } from '../fn/question-bank/api-question-bank-get-tasks-data-post';
import { ApiQuestionBankGetTasksDataPost$Params } from '../fn/question-bank/api-question-bank-get-tasks-data-post';
import { apiQuestionBankGetTasksDataPost$Plain } from '../fn/question-bank/api-question-bank-get-tasks-data-post-plain';
import { ApiQuestionBankGetTasksDataPost$Plain$Params } from '../fn/question-bank/api-question-bank-get-tasks-data-post-plain';
import { apiQuestionBankUpdateBankPost } from '../fn/question-bank/api-question-bank-update-bank-post';
import { ApiQuestionBankUpdateBankPost$Params } from '../fn/question-bank/api-question-bank-update-bank-post';
import { apiQuestionBankUpdateBankPost$Plain } from '../fn/question-bank/api-question-bank-update-bank-post-plain';
import { ApiQuestionBankUpdateBankPost$Plain$Params } from '../fn/question-bank/api-question-bank-update-bank-post-plain';
import { CreateBankCommandResult } from '../models/create-bank-command-result';
import { DeleteBankCommandResult } from '../models/delete-bank-command-result';
import { GetBankQueryResult } from '../models/get-bank-query-result';
import { GetTasksQueryResult } from '../models/get-tasks-query-result';
import { UpdateBankCommandResult } from '../models/update-bank-command-result';

@Injectable({ providedIn: 'root' })
export class QuestionBankService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiQuestionBankGetBankPost()` */
  static readonly ApiQuestionBankGetBankPostPath = '/api/QuestionBank/GetBank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankGetBankPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetBankPost$Plain$Response(params?: ApiQuestionBankGetBankPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetBankQueryResult>> {
    return apiQuestionBankGetBankPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankGetBankPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetBankPost$Plain(params?: ApiQuestionBankGetBankPost$Plain$Params, context?: HttpContext): Observable<GetBankQueryResult> {
    return this.apiQuestionBankGetBankPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetBankQueryResult>): GetBankQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankGetBankPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetBankPost$Response(params?: ApiQuestionBankGetBankPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetBankQueryResult>> {
    return apiQuestionBankGetBankPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankGetBankPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetBankPost(params?: ApiQuestionBankGetBankPost$Params, context?: HttpContext): Observable<GetBankQueryResult> {
    return this.apiQuestionBankGetBankPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetBankQueryResult>): GetBankQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionBankGetTasksDataPost()` */
  static readonly ApiQuestionBankGetTasksDataPostPath = '/api/QuestionBank/GetTasksData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankGetTasksDataPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetTasksDataPost$Plain$Response(params?: ApiQuestionBankGetTasksDataPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTasksQueryResult>> {
    return apiQuestionBankGetTasksDataPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankGetTasksDataPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetTasksDataPost$Plain(params?: ApiQuestionBankGetTasksDataPost$Plain$Params, context?: HttpContext): Observable<GetTasksQueryResult> {
    return this.apiQuestionBankGetTasksDataPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTasksQueryResult>): GetTasksQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankGetTasksDataPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetTasksDataPost$Response(params?: ApiQuestionBankGetTasksDataPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTasksQueryResult>> {
    return apiQuestionBankGetTasksDataPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankGetTasksDataPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankGetTasksDataPost(params?: ApiQuestionBankGetTasksDataPost$Params, context?: HttpContext): Observable<GetTasksQueryResult> {
    return this.apiQuestionBankGetTasksDataPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTasksQueryResult>): GetTasksQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionBankCreateBankPost()` */
  static readonly ApiQuestionBankCreateBankPostPath = '/api/QuestionBank/CreateBank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankCreateBankPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankCreateBankPost$Plain$Response(params?: ApiQuestionBankCreateBankPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateBankCommandResult>> {
    return apiQuestionBankCreateBankPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankCreateBankPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankCreateBankPost$Plain(params?: ApiQuestionBankCreateBankPost$Plain$Params, context?: HttpContext): Observable<CreateBankCommandResult> {
    return this.apiQuestionBankCreateBankPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateBankCommandResult>): CreateBankCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankCreateBankPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankCreateBankPost$Response(params?: ApiQuestionBankCreateBankPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateBankCommandResult>> {
    return apiQuestionBankCreateBankPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankCreateBankPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankCreateBankPost(params?: ApiQuestionBankCreateBankPost$Params, context?: HttpContext): Observable<CreateBankCommandResult> {
    return this.apiQuestionBankCreateBankPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateBankCommandResult>): CreateBankCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionBankUpdateBankPost()` */
  static readonly ApiQuestionBankUpdateBankPostPath = '/api/QuestionBank/UpdateBank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankUpdateBankPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankUpdateBankPost$Plain$Response(params?: ApiQuestionBankUpdateBankPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateBankCommandResult>> {
    return apiQuestionBankUpdateBankPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankUpdateBankPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankUpdateBankPost$Plain(params?: ApiQuestionBankUpdateBankPost$Plain$Params, context?: HttpContext): Observable<UpdateBankCommandResult> {
    return this.apiQuestionBankUpdateBankPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateBankCommandResult>): UpdateBankCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankUpdateBankPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankUpdateBankPost$Response(params?: ApiQuestionBankUpdateBankPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateBankCommandResult>> {
    return apiQuestionBankUpdateBankPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankUpdateBankPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankUpdateBankPost(params?: ApiQuestionBankUpdateBankPost$Params, context?: HttpContext): Observable<UpdateBankCommandResult> {
    return this.apiQuestionBankUpdateBankPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateBankCommandResult>): UpdateBankCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQuestionBankDeleteBankPost()` */
  static readonly ApiQuestionBankDeleteBankPostPath = '/api/QuestionBank/DeleteBank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankDeleteBankPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankDeleteBankPost$Plain$Response(params?: ApiQuestionBankDeleteBankPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteBankCommandResult>> {
    return apiQuestionBankDeleteBankPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankDeleteBankPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankDeleteBankPost$Plain(params?: ApiQuestionBankDeleteBankPost$Plain$Params, context?: HttpContext): Observable<DeleteBankCommandResult> {
    return this.apiQuestionBankDeleteBankPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteBankCommandResult>): DeleteBankCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQuestionBankDeleteBankPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankDeleteBankPost$Response(params?: ApiQuestionBankDeleteBankPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteBankCommandResult>> {
    return apiQuestionBankDeleteBankPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQuestionBankDeleteBankPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQuestionBankDeleteBankPost(params?: ApiQuestionBankDeleteBankPost$Params, context?: HttpContext): Observable<DeleteBankCommandResult> {
    return this.apiQuestionBankDeleteBankPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteBankCommandResult>): DeleteBankCommandResult => r.body)
    );
  }

}
