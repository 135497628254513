/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSuperAdminActionsResetStudentWallPost } from '../fn/super-admin-actions/api-super-admin-actions-reset-student-wall-post';
import { ApiSuperAdminActionsResetStudentWallPost$Params } from '../fn/super-admin-actions/api-super-admin-actions-reset-student-wall-post';
import { apiSuperAdminActionsResetStudentWallPost$Plain } from '../fn/super-admin-actions/api-super-admin-actions-reset-student-wall-post-plain';
import { ApiSuperAdminActionsResetStudentWallPost$Plain$Params } from '../fn/super-admin-actions/api-super-admin-actions-reset-student-wall-post-plain';
import { ResetStudentWallQueryResult } from '../models/reset-student-wall-query-result';

@Injectable({ providedIn: 'root' })
export class SuperAdminActionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSuperAdminActionsResetStudentWallPost()` */
  static readonly ApiSuperAdminActionsResetStudentWallPostPath = '/api/SuperAdminActions/ResetStudentWall';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsResetStudentWallPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsResetStudentWallPost$Plain$Response(params?: ApiSuperAdminActionsResetStudentWallPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetStudentWallQueryResult>> {
    return apiSuperAdminActionsResetStudentWallPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsResetStudentWallPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsResetStudentWallPost$Plain(params?: ApiSuperAdminActionsResetStudentWallPost$Plain$Params, context?: HttpContext): Observable<ResetStudentWallQueryResult> {
    return this.apiSuperAdminActionsResetStudentWallPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetStudentWallQueryResult>): ResetStudentWallQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsResetStudentWallPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsResetStudentWallPost$Response(params?: ApiSuperAdminActionsResetStudentWallPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetStudentWallQueryResult>> {
    return apiSuperAdminActionsResetStudentWallPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsResetStudentWallPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsResetStudentWallPost(params?: ApiSuperAdminActionsResetStudentWallPost$Params, context?: HttpContext): Observable<ResetStudentWallQueryResult> {
    return this.apiSuperAdminActionsResetStudentWallPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetStudentWallQueryResult>): ResetStudentWallQueryResult => r.body)
    );
  }

}
