/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcceptAllRequestsCommandResult } from '../models/accept-all-requests-command-result';
import { AcceptOrRejectRequestCommandResult } from '../models/accept-or-reject-request-command-result';
import { apiRequestsAcceptAllRequestsPost } from '../fn/requests/api-requests-accept-all-requests-post';
import { ApiRequestsAcceptAllRequestsPost$Params } from '../fn/requests/api-requests-accept-all-requests-post';
import { apiRequestsAcceptAllRequestsPost$Plain } from '../fn/requests/api-requests-accept-all-requests-post-plain';
import { ApiRequestsAcceptAllRequestsPost$Plain$Params } from '../fn/requests/api-requests-accept-all-requests-post-plain';
import { apiRequestsAcceptOrRejectRequestPost } from '../fn/requests/api-requests-accept-or-reject-request-post';
import { ApiRequestsAcceptOrRejectRequestPost$Params } from '../fn/requests/api-requests-accept-or-reject-request-post';
import { apiRequestsAcceptOrRejectRequestPost$Plain } from '../fn/requests/api-requests-accept-or-reject-request-post-plain';
import { ApiRequestsAcceptOrRejectRequestPost$Plain$Params } from '../fn/requests/api-requests-accept-or-reject-request-post-plain';
import { apiRequestsCreateRequestPost } from '../fn/requests/api-requests-create-request-post';
import { ApiRequestsCreateRequestPost$Params } from '../fn/requests/api-requests-create-request-post';
import { apiRequestsCreateRequestPost$Plain } from '../fn/requests/api-requests-create-request-post-plain';
import { ApiRequestsCreateRequestPost$Plain$Params } from '../fn/requests/api-requests-create-request-post-plain';
import { apiRequestsDeleteRequestPost } from '../fn/requests/api-requests-delete-request-post';
import { ApiRequestsDeleteRequestPost$Params } from '../fn/requests/api-requests-delete-request-post';
import { apiRequestsDeleteRequestPost$Plain } from '../fn/requests/api-requests-delete-request-post-plain';
import { ApiRequestsDeleteRequestPost$Plain$Params } from '../fn/requests/api-requests-delete-request-post-plain';
import { apiRequestsGetCourseTeacherRequestsPost } from '../fn/requests/api-requests-get-course-teacher-requests-post';
import { ApiRequestsGetCourseTeacherRequestsPost$Params } from '../fn/requests/api-requests-get-course-teacher-requests-post';
import { apiRequestsGetCourseTeacherRequestsPost$Plain } from '../fn/requests/api-requests-get-course-teacher-requests-post-plain';
import { ApiRequestsGetCourseTeacherRequestsPost$Plain$Params } from '../fn/requests/api-requests-get-course-teacher-requests-post-plain';
import { apiRequestsGetRequestsPost } from '../fn/requests/api-requests-get-requests-post';
import { ApiRequestsGetRequestsPost$Params } from '../fn/requests/api-requests-get-requests-post';
import { apiRequestsGetRequestsPost$Plain } from '../fn/requests/api-requests-get-requests-post-plain';
import { ApiRequestsGetRequestsPost$Plain$Params } from '../fn/requests/api-requests-get-requests-post-plain';
import { apiRequestsGetTeacherRequestsPost } from '../fn/requests/api-requests-get-teacher-requests-post';
import { ApiRequestsGetTeacherRequestsPost$Params } from '../fn/requests/api-requests-get-teacher-requests-post';
import { apiRequestsGetTeacherRequestsPost$Plain } from '../fn/requests/api-requests-get-teacher-requests-post-plain';
import { ApiRequestsGetTeacherRequestsPost$Plain$Params } from '../fn/requests/api-requests-get-teacher-requests-post-plain';
import { CreateRequestCommandResult } from '../models/create-request-command-result';
import { DeleteRequestCommandResult } from '../models/delete-request-command-result';
import { GetCourseTeacherRequestsQueryResult } from '../models/get-course-teacher-requests-query-result';
import { GetRequestsQueryResult } from '../models/get-requests-query-result';
import { GetTeacherRequestsQueryResult } from '../models/get-teacher-requests-query-result';

@Injectable({ providedIn: 'root' })
export class RequestsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRequestsGetRequestsPost()` */
  static readonly ApiRequestsGetRequestsPostPath = '/api/Requests/GetRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetRequestsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetRequestsPost$Plain$Response(params?: ApiRequestsGetRequestsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRequestsQueryResult>> {
    return apiRequestsGetRequestsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetRequestsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetRequestsPost$Plain(params?: ApiRequestsGetRequestsPost$Plain$Params, context?: HttpContext): Observable<GetRequestsQueryResult> {
    return this.apiRequestsGetRequestsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRequestsQueryResult>): GetRequestsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetRequestsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetRequestsPost$Response(params?: ApiRequestsGetRequestsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRequestsQueryResult>> {
    return apiRequestsGetRequestsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetRequestsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetRequestsPost(params?: ApiRequestsGetRequestsPost$Params, context?: HttpContext): Observable<GetRequestsQueryResult> {
    return this.apiRequestsGetRequestsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRequestsQueryResult>): GetRequestsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsGetTeacherRequestsPost()` */
  static readonly ApiRequestsGetTeacherRequestsPostPath = '/api/Requests/GetTeacherRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetTeacherRequestsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetTeacherRequestsPost$Plain$Response(params?: ApiRequestsGetTeacherRequestsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherRequestsQueryResult>> {
    return apiRequestsGetTeacherRequestsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetTeacherRequestsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetTeacherRequestsPost$Plain(params?: ApiRequestsGetTeacherRequestsPost$Plain$Params, context?: HttpContext): Observable<GetTeacherRequestsQueryResult> {
    return this.apiRequestsGetTeacherRequestsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherRequestsQueryResult>): GetTeacherRequestsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetTeacherRequestsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetTeacherRequestsPost$Response(params?: ApiRequestsGetTeacherRequestsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetTeacherRequestsQueryResult>> {
    return apiRequestsGetTeacherRequestsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetTeacherRequestsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetTeacherRequestsPost(params?: ApiRequestsGetTeacherRequestsPost$Params, context?: HttpContext): Observable<GetTeacherRequestsQueryResult> {
    return this.apiRequestsGetTeacherRequestsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTeacherRequestsQueryResult>): GetTeacherRequestsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsGetCourseTeacherRequestsPost()` */
  static readonly ApiRequestsGetCourseTeacherRequestsPostPath = '/api/Requests/GetCourseTeacherRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetCourseTeacherRequestsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetCourseTeacherRequestsPost$Plain$Response(params?: ApiRequestsGetCourseTeacherRequestsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherRequestsQueryResult>> {
    return apiRequestsGetCourseTeacherRequestsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetCourseTeacherRequestsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetCourseTeacherRequestsPost$Plain(params?: ApiRequestsGetCourseTeacherRequestsPost$Plain$Params, context?: HttpContext): Observable<GetCourseTeacherRequestsQueryResult> {
    return this.apiRequestsGetCourseTeacherRequestsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherRequestsQueryResult>): GetCourseTeacherRequestsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsGetCourseTeacherRequestsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetCourseTeacherRequestsPost$Response(params?: ApiRequestsGetCourseTeacherRequestsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCourseTeacherRequestsQueryResult>> {
    return apiRequestsGetCourseTeacherRequestsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsGetCourseTeacherRequestsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsGetCourseTeacherRequestsPost(params?: ApiRequestsGetCourseTeacherRequestsPost$Params, context?: HttpContext): Observable<GetCourseTeacherRequestsQueryResult> {
    return this.apiRequestsGetCourseTeacherRequestsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetCourseTeacherRequestsQueryResult>): GetCourseTeacherRequestsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsAcceptOrRejectRequestPost()` */
  static readonly ApiRequestsAcceptOrRejectRequestPostPath = '/api/Requests/AcceptOrRejectRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsAcceptOrRejectRequestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptOrRejectRequestPost$Plain$Response(params?: ApiRequestsAcceptOrRejectRequestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AcceptOrRejectRequestCommandResult>> {
    return apiRequestsAcceptOrRejectRequestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsAcceptOrRejectRequestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptOrRejectRequestPost$Plain(params?: ApiRequestsAcceptOrRejectRequestPost$Plain$Params, context?: HttpContext): Observable<AcceptOrRejectRequestCommandResult> {
    return this.apiRequestsAcceptOrRejectRequestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcceptOrRejectRequestCommandResult>): AcceptOrRejectRequestCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsAcceptOrRejectRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptOrRejectRequestPost$Response(params?: ApiRequestsAcceptOrRejectRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<AcceptOrRejectRequestCommandResult>> {
    return apiRequestsAcceptOrRejectRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsAcceptOrRejectRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptOrRejectRequestPost(params?: ApiRequestsAcceptOrRejectRequestPost$Params, context?: HttpContext): Observable<AcceptOrRejectRequestCommandResult> {
    return this.apiRequestsAcceptOrRejectRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcceptOrRejectRequestCommandResult>): AcceptOrRejectRequestCommandResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsAcceptAllRequestsPost()` */
  static readonly ApiRequestsAcceptAllRequestsPostPath = '/api/Requests/AcceptAllRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsAcceptAllRequestsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptAllRequestsPost$Plain$Response(params?: ApiRequestsAcceptAllRequestsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<AcceptAllRequestsCommandResult>> {
    return apiRequestsAcceptAllRequestsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsAcceptAllRequestsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptAllRequestsPost$Plain(params?: ApiRequestsAcceptAllRequestsPost$Plain$Params, context?: HttpContext): Observable<AcceptAllRequestsCommandResult> {
    return this.apiRequestsAcceptAllRequestsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcceptAllRequestsCommandResult>): AcceptAllRequestsCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsAcceptAllRequestsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptAllRequestsPost$Response(params?: ApiRequestsAcceptAllRequestsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<AcceptAllRequestsCommandResult>> {
    return apiRequestsAcceptAllRequestsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsAcceptAllRequestsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsAcceptAllRequestsPost(params?: ApiRequestsAcceptAllRequestsPost$Params, context?: HttpContext): Observable<AcceptAllRequestsCommandResult> {
    return this.apiRequestsAcceptAllRequestsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcceptAllRequestsCommandResult>): AcceptAllRequestsCommandResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsCreateRequestPost()` */
  static readonly ApiRequestsCreateRequestPostPath = '/api/Requests/CreateRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsCreateRequestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsCreateRequestPost$Plain$Response(params?: ApiRequestsCreateRequestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRequestCommandResult>> {
    return apiRequestsCreateRequestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsCreateRequestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsCreateRequestPost$Plain(params?: ApiRequestsCreateRequestPost$Plain$Params, context?: HttpContext): Observable<CreateRequestCommandResult> {
    return this.apiRequestsCreateRequestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRequestCommandResult>): CreateRequestCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsCreateRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsCreateRequestPost$Response(params?: ApiRequestsCreateRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateRequestCommandResult>> {
    return apiRequestsCreateRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsCreateRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsCreateRequestPost(params?: ApiRequestsCreateRequestPost$Params, context?: HttpContext): Observable<CreateRequestCommandResult> {
    return this.apiRequestsCreateRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateRequestCommandResult>): CreateRequestCommandResult => r.body)
    );
  }

  /** Path part for operation `apiRequestsDeleteRequestPost()` */
  static readonly ApiRequestsDeleteRequestPostPath = '/api/Requests/DeleteRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsDeleteRequestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsDeleteRequestPost$Plain$Response(params?: ApiRequestsDeleteRequestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteRequestCommandResult>> {
    return apiRequestsDeleteRequestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsDeleteRequestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsDeleteRequestPost$Plain(params?: ApiRequestsDeleteRequestPost$Plain$Params, context?: HttpContext): Observable<DeleteRequestCommandResult> {
    return this.apiRequestsDeleteRequestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteRequestCommandResult>): DeleteRequestCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestsDeleteRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsDeleteRequestPost$Response(params?: ApiRequestsDeleteRequestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteRequestCommandResult>> {
    return apiRequestsDeleteRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRequestsDeleteRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRequestsDeleteRequestPost(params?: ApiRequestsDeleteRequestPost$Params, context?: HttpContext): Observable<DeleteRequestCommandResult> {
    return this.apiRequestsDeleteRequestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteRequestCommandResult>): DeleteRequestCommandResult => r.body)
    );
  }

}
