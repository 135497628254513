/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiStudentTaskCreateStudentTaskPost } from '../fn/student-task/api-student-task-create-student-task-post';
import { ApiStudentTaskCreateStudentTaskPost$Params } from '../fn/student-task/api-student-task-create-student-task-post';
import { apiStudentTaskCreateStudentTaskPost$Plain } from '../fn/student-task/api-student-task-create-student-task-post-plain';
import { ApiStudentTaskCreateStudentTaskPost$Plain$Params } from '../fn/student-task/api-student-task-create-student-task-post-plain';
import { apiStudentTaskDeleteStudentTaskPost } from '../fn/student-task/api-student-task-delete-student-task-post';
import { ApiStudentTaskDeleteStudentTaskPost$Params } from '../fn/student-task/api-student-task-delete-student-task-post';
import { apiStudentTaskDeleteStudentTaskPost$Plain } from '../fn/student-task/api-student-task-delete-student-task-post-plain';
import { ApiStudentTaskDeleteStudentTaskPost$Plain$Params } from '../fn/student-task/api-student-task-delete-student-task-post-plain';
import { apiStudentTaskGetActiveStudentTaskPost } from '../fn/student-task/api-student-task-get-active-student-task-post';
import { ApiStudentTaskGetActiveStudentTaskPost$Params } from '../fn/student-task/api-student-task-get-active-student-task-post';
import { apiStudentTaskGetActiveStudentTaskPost$Plain } from '../fn/student-task/api-student-task-get-active-student-task-post-plain';
import { ApiStudentTaskGetActiveStudentTaskPost$Plain$Params } from '../fn/student-task/api-student-task-get-active-student-task-post-plain';
import { apiStudentTaskGetStudentTaskPost } from '../fn/student-task/api-student-task-get-student-task-post';
import { ApiStudentTaskGetStudentTaskPost$Params } from '../fn/student-task/api-student-task-get-student-task-post';
import { apiStudentTaskGetStudentTaskPost$Plain } from '../fn/student-task/api-student-task-get-student-task-post-plain';
import { ApiStudentTaskGetStudentTaskPost$Plain$Params } from '../fn/student-task/api-student-task-get-student-task-post-plain';
import { apiStudentTaskGetStudentTasksPost } from '../fn/student-task/api-student-task-get-student-tasks-post';
import { ApiStudentTaskGetStudentTasksPost$Params } from '../fn/student-task/api-student-task-get-student-tasks-post';
import { apiStudentTaskGetStudentTasksPost$Plain } from '../fn/student-task/api-student-task-get-student-tasks-post-plain';
import { ApiStudentTaskGetStudentTasksPost$Plain$Params } from '../fn/student-task/api-student-task-get-student-tasks-post-plain';
import { apiStudentTaskUpdateStudentTaskPost } from '../fn/student-task/api-student-task-update-student-task-post';
import { ApiStudentTaskUpdateStudentTaskPost$Params } from '../fn/student-task/api-student-task-update-student-task-post';
import { apiStudentTaskUpdateStudentTaskPost$Plain } from '../fn/student-task/api-student-task-update-student-task-post-plain';
import { ApiStudentTaskUpdateStudentTaskPost$Plain$Params } from '../fn/student-task/api-student-task-update-student-task-post-plain';
import { CreateStudentTaskCommandResult } from '../models/create-student-task-command-result';
import { DeleteStudentTaskCommandResult } from '../models/delete-student-task-command-result';
import { GetActiveStudentTaskQueryResult } from '../models/get-active-student-task-query-result';
import { GetStudentTaskQueryResult } from '../models/get-student-task-query-result';
import { GetStudentTasksQueryResult } from '../models/get-student-tasks-query-result';
import { UpdateStudentTaskCommandResult } from '../models/update-student-task-command-result';

@Injectable({ providedIn: 'root' })
export class StudentTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStudentTaskGetStudentTaskPost()` */
  static readonly ApiStudentTaskGetStudentTaskPostPath = '/api/StudentTask/GetStudentTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetStudentTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTaskPost$Plain$Response(params?: ApiStudentTaskGetStudentTaskPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentTaskQueryResult>> {
    return apiStudentTaskGetStudentTaskPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetStudentTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTaskPost$Plain(params?: ApiStudentTaskGetStudentTaskPost$Plain$Params, context?: HttpContext): Observable<GetStudentTaskQueryResult> {
    return this.apiStudentTaskGetStudentTaskPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentTaskQueryResult>): GetStudentTaskQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetStudentTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTaskPost$Response(params?: ApiStudentTaskGetStudentTaskPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentTaskQueryResult>> {
    return apiStudentTaskGetStudentTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetStudentTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTaskPost(params?: ApiStudentTaskGetStudentTaskPost$Params, context?: HttpContext): Observable<GetStudentTaskQueryResult> {
    return this.apiStudentTaskGetStudentTaskPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentTaskQueryResult>): GetStudentTaskQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentTaskGetStudentTasksPost()` */
  static readonly ApiStudentTaskGetStudentTasksPostPath = '/api/StudentTask/GetStudentTasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetStudentTasksPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTasksPost$Plain$Response(params?: ApiStudentTaskGetStudentTasksPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentTasksQueryResult>> {
    return apiStudentTaskGetStudentTasksPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetStudentTasksPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTasksPost$Plain(params?: ApiStudentTaskGetStudentTasksPost$Plain$Params, context?: HttpContext): Observable<GetStudentTasksQueryResult> {
    return this.apiStudentTaskGetStudentTasksPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentTasksQueryResult>): GetStudentTasksQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetStudentTasksPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTasksPost$Response(params?: ApiStudentTaskGetStudentTasksPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetStudentTasksQueryResult>> {
    return apiStudentTaskGetStudentTasksPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetStudentTasksPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetStudentTasksPost(params?: ApiStudentTaskGetStudentTasksPost$Params, context?: HttpContext): Observable<GetStudentTasksQueryResult> {
    return this.apiStudentTaskGetStudentTasksPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetStudentTasksQueryResult>): GetStudentTasksQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentTaskGetActiveStudentTaskPost()` */
  static readonly ApiStudentTaskGetActiveStudentTaskPostPath = '/api/StudentTask/GetActiveStudentTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetActiveStudentTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetActiveStudentTaskPost$Plain$Response(params?: ApiStudentTaskGetActiveStudentTaskPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetActiveStudentTaskQueryResult>> {
    return apiStudentTaskGetActiveStudentTaskPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetActiveStudentTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetActiveStudentTaskPost$Plain(params?: ApiStudentTaskGetActiveStudentTaskPost$Plain$Params, context?: HttpContext): Observable<GetActiveStudentTaskQueryResult> {
    return this.apiStudentTaskGetActiveStudentTaskPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetActiveStudentTaskQueryResult>): GetActiveStudentTaskQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskGetActiveStudentTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetActiveStudentTaskPost$Response(params?: ApiStudentTaskGetActiveStudentTaskPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetActiveStudentTaskQueryResult>> {
    return apiStudentTaskGetActiveStudentTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskGetActiveStudentTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskGetActiveStudentTaskPost(params?: ApiStudentTaskGetActiveStudentTaskPost$Params, context?: HttpContext): Observable<GetActiveStudentTaskQueryResult> {
    return this.apiStudentTaskGetActiveStudentTaskPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetActiveStudentTaskQueryResult>): GetActiveStudentTaskQueryResult => r.body)
    );
  }

  /** Path part for operation `apiStudentTaskCreateStudentTaskPost()` */
  static readonly ApiStudentTaskCreateStudentTaskPostPath = '/api/StudentTask/CreateStudentTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskCreateStudentTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskCreateStudentTaskPost$Plain$Response(params?: ApiStudentTaskCreateStudentTaskPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentTaskCommandResult>> {
    return apiStudentTaskCreateStudentTaskPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskCreateStudentTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskCreateStudentTaskPost$Plain(params?: ApiStudentTaskCreateStudentTaskPost$Plain$Params, context?: HttpContext): Observable<CreateStudentTaskCommandResult> {
    return this.apiStudentTaskCreateStudentTaskPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentTaskCommandResult>): CreateStudentTaskCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskCreateStudentTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskCreateStudentTaskPost$Response(params?: ApiStudentTaskCreateStudentTaskPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateStudentTaskCommandResult>> {
    return apiStudentTaskCreateStudentTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskCreateStudentTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskCreateStudentTaskPost(params?: ApiStudentTaskCreateStudentTaskPost$Params, context?: HttpContext): Observable<CreateStudentTaskCommandResult> {
    return this.apiStudentTaskCreateStudentTaskPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateStudentTaskCommandResult>): CreateStudentTaskCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentTaskUpdateStudentTaskPost()` */
  static readonly ApiStudentTaskUpdateStudentTaskPostPath = '/api/StudentTask/UpdateStudentTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskUpdateStudentTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskUpdateStudentTaskPost$Plain$Response(params?: ApiStudentTaskUpdateStudentTaskPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStudentTaskCommandResult>> {
    return apiStudentTaskUpdateStudentTaskPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskUpdateStudentTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskUpdateStudentTaskPost$Plain(params?: ApiStudentTaskUpdateStudentTaskPost$Plain$Params, context?: HttpContext): Observable<UpdateStudentTaskCommandResult> {
    return this.apiStudentTaskUpdateStudentTaskPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStudentTaskCommandResult>): UpdateStudentTaskCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskUpdateStudentTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskUpdateStudentTaskPost$Response(params?: ApiStudentTaskUpdateStudentTaskPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateStudentTaskCommandResult>> {
    return apiStudentTaskUpdateStudentTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskUpdateStudentTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskUpdateStudentTaskPost(params?: ApiStudentTaskUpdateStudentTaskPost$Params, context?: HttpContext): Observable<UpdateStudentTaskCommandResult> {
    return this.apiStudentTaskUpdateStudentTaskPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateStudentTaskCommandResult>): UpdateStudentTaskCommandResult => r.body)
    );
  }

  /** Path part for operation `apiStudentTaskDeleteStudentTaskPost()` */
  static readonly ApiStudentTaskDeleteStudentTaskPostPath = '/api/StudentTask/DeleteStudentTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskDeleteStudentTaskPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskDeleteStudentTaskPost$Plain$Response(params?: ApiStudentTaskDeleteStudentTaskPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStudentTaskCommandResult>> {
    return apiStudentTaskDeleteStudentTaskPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskDeleteStudentTaskPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskDeleteStudentTaskPost$Plain(params?: ApiStudentTaskDeleteStudentTaskPost$Plain$Params, context?: HttpContext): Observable<DeleteStudentTaskCommandResult> {
    return this.apiStudentTaskDeleteStudentTaskPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStudentTaskCommandResult>): DeleteStudentTaskCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStudentTaskDeleteStudentTaskPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskDeleteStudentTaskPost$Response(params?: ApiStudentTaskDeleteStudentTaskPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteStudentTaskCommandResult>> {
    return apiStudentTaskDeleteStudentTaskPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStudentTaskDeleteStudentTaskPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStudentTaskDeleteStudentTaskPost(params?: ApiStudentTaskDeleteStudentTaskPost$Params, context?: HttpContext): Observable<DeleteStudentTaskCommandResult> {
    return this.apiStudentTaskDeleteStudentTaskPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteStudentTaskCommandResult>): DeleteStudentTaskCommandResult => r.body)
    );
  }

}
