/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAnswerCreateAnswerPost } from '../fn/answer/api-answer-create-answer-post';
import { ApiAnswerCreateAnswerPost$Params } from '../fn/answer/api-answer-create-answer-post';
import { apiAnswerCreateAnswerPost$Plain } from '../fn/answer/api-answer-create-answer-post-plain';
import { ApiAnswerCreateAnswerPost$Plain$Params } from '../fn/answer/api-answer-create-answer-post-plain';
import { apiAnswerDeleteAnswerPost } from '../fn/answer/api-answer-delete-answer-post';
import { ApiAnswerDeleteAnswerPost$Params } from '../fn/answer/api-answer-delete-answer-post';
import { apiAnswerDeleteAnswerPost$Plain } from '../fn/answer/api-answer-delete-answer-post-plain';
import { ApiAnswerDeleteAnswerPost$Plain$Params } from '../fn/answer/api-answer-delete-answer-post-plain';
import { apiAnswerGetAnswerPost } from '../fn/answer/api-answer-get-answer-post';
import { ApiAnswerGetAnswerPost$Params } from '../fn/answer/api-answer-get-answer-post';
import { apiAnswerGetAnswerPost$Plain } from '../fn/answer/api-answer-get-answer-post-plain';
import { ApiAnswerGetAnswerPost$Plain$Params } from '../fn/answer/api-answer-get-answer-post-plain';
import { apiAnswerGetAnswersPost } from '../fn/answer/api-answer-get-answers-post';
import { ApiAnswerGetAnswersPost$Params } from '../fn/answer/api-answer-get-answers-post';
import { apiAnswerGetAnswersPost$Plain } from '../fn/answer/api-answer-get-answers-post-plain';
import { ApiAnswerGetAnswersPost$Plain$Params } from '../fn/answer/api-answer-get-answers-post-plain';
import { apiAnswerUpdateAnswerPost } from '../fn/answer/api-answer-update-answer-post';
import { ApiAnswerUpdateAnswerPost$Params } from '../fn/answer/api-answer-update-answer-post';
import { apiAnswerUpdateAnswerPost$Plain } from '../fn/answer/api-answer-update-answer-post-plain';
import { ApiAnswerUpdateAnswerPost$Plain$Params } from '../fn/answer/api-answer-update-answer-post-plain';
import { CreateAnswerCommandResult } from '../models/create-answer-command-result';
import { DeleteAnswerCommandResult } from '../models/delete-answer-command-result';
import { GetAnswerQueryResult } from '../models/get-answer-query-result';
import { GetAnswersQueryResult } from '../models/get-answers-query-result';
import { UpdateAnswerCommandResult } from '../models/update-answer-command-result';

@Injectable({ providedIn: 'root' })
export class AnswerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAnswerGetAnswerPost()` */
  static readonly ApiAnswerGetAnswerPostPath = '/api/Answer/GetAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerGetAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswerPost$Plain$Response(params?: ApiAnswerGetAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAnswerQueryResult>> {
    return apiAnswerGetAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerGetAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswerPost$Plain(params?: ApiAnswerGetAnswerPost$Plain$Params, context?: HttpContext): Observable<GetAnswerQueryResult> {
    return this.apiAnswerGetAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAnswerQueryResult>): GetAnswerQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerGetAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswerPost$Response(params?: ApiAnswerGetAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAnswerQueryResult>> {
    return apiAnswerGetAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerGetAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswerPost(params?: ApiAnswerGetAnswerPost$Params, context?: HttpContext): Observable<GetAnswerQueryResult> {
    return this.apiAnswerGetAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAnswerQueryResult>): GetAnswerQueryResult => r.body)
    );
  }

  /** Path part for operation `apiAnswerGetAnswersPost()` */
  static readonly ApiAnswerGetAnswersPostPath = '/api/Answer/GetAnswers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerGetAnswersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswersPost$Plain$Response(params?: ApiAnswerGetAnswersPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAnswersQueryResult>> {
    return apiAnswerGetAnswersPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerGetAnswersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswersPost$Plain(params?: ApiAnswerGetAnswersPost$Plain$Params, context?: HttpContext): Observable<GetAnswersQueryResult> {
    return this.apiAnswerGetAnswersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAnswersQueryResult>): GetAnswersQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerGetAnswersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswersPost$Response(params?: ApiAnswerGetAnswersPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAnswersQueryResult>> {
    return apiAnswerGetAnswersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerGetAnswersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerGetAnswersPost(params?: ApiAnswerGetAnswersPost$Params, context?: HttpContext): Observable<GetAnswersQueryResult> {
    return this.apiAnswerGetAnswersPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAnswersQueryResult>): GetAnswersQueryResult => r.body)
    );
  }

  /** Path part for operation `apiAnswerCreateAnswerPost()` */
  static readonly ApiAnswerCreateAnswerPostPath = '/api/Answer/CreateAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerCreateAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerCreateAnswerPost$Plain$Response(params?: ApiAnswerCreateAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateAnswerCommandResult>> {
    return apiAnswerCreateAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerCreateAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerCreateAnswerPost$Plain(params?: ApiAnswerCreateAnswerPost$Plain$Params, context?: HttpContext): Observable<CreateAnswerCommandResult> {
    return this.apiAnswerCreateAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateAnswerCommandResult>): CreateAnswerCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerCreateAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerCreateAnswerPost$Response(params?: ApiAnswerCreateAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateAnswerCommandResult>> {
    return apiAnswerCreateAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerCreateAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerCreateAnswerPost(params?: ApiAnswerCreateAnswerPost$Params, context?: HttpContext): Observable<CreateAnswerCommandResult> {
    return this.apiAnswerCreateAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateAnswerCommandResult>): CreateAnswerCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAnswerUpdateAnswerPost()` */
  static readonly ApiAnswerUpdateAnswerPostPath = '/api/Answer/UpdateAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerUpdateAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerUpdateAnswerPost$Plain$Response(params?: ApiAnswerUpdateAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateAnswerCommandResult>> {
    return apiAnswerUpdateAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerUpdateAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerUpdateAnswerPost$Plain(params?: ApiAnswerUpdateAnswerPost$Plain$Params, context?: HttpContext): Observable<UpdateAnswerCommandResult> {
    return this.apiAnswerUpdateAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateAnswerCommandResult>): UpdateAnswerCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerUpdateAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerUpdateAnswerPost$Response(params?: ApiAnswerUpdateAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateAnswerCommandResult>> {
    return apiAnswerUpdateAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerUpdateAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerUpdateAnswerPost(params?: ApiAnswerUpdateAnswerPost$Params, context?: HttpContext): Observable<UpdateAnswerCommandResult> {
    return this.apiAnswerUpdateAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateAnswerCommandResult>): UpdateAnswerCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAnswerDeleteAnswerPost()` */
  static readonly ApiAnswerDeleteAnswerPostPath = '/api/Answer/DeleteAnswer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerDeleteAnswerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerDeleteAnswerPost$Plain$Response(params?: ApiAnswerDeleteAnswerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteAnswerCommandResult>> {
    return apiAnswerDeleteAnswerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerDeleteAnswerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerDeleteAnswerPost$Plain(params?: ApiAnswerDeleteAnswerPost$Plain$Params, context?: HttpContext): Observable<DeleteAnswerCommandResult> {
    return this.apiAnswerDeleteAnswerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteAnswerCommandResult>): DeleteAnswerCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAnswerDeleteAnswerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerDeleteAnswerPost$Response(params?: ApiAnswerDeleteAnswerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteAnswerCommandResult>> {
    return apiAnswerDeleteAnswerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAnswerDeleteAnswerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAnswerDeleteAnswerPost(params?: ApiAnswerDeleteAnswerPost$Params, context?: HttpContext): Observable<DeleteAnswerCommandResult> {
    return this.apiAnswerDeleteAnswerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteAnswerCommandResult>): DeleteAnswerCommandResult => r.body)
    );
  }

}
