/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAccountChangeEmailPost } from '../fn/account/api-account-change-email-post';
import { ApiAccountChangeEmailPost$Params } from '../fn/account/api-account-change-email-post';
import { apiAccountChangeEmailPost$Plain } from '../fn/account/api-account-change-email-post-plain';
import { ApiAccountChangeEmailPost$Plain$Params } from '../fn/account/api-account-change-email-post-plain';
import { apiAccountChangeUserPasswordPost } from '../fn/account/api-account-change-user-password-post';
import { ApiAccountChangeUserPasswordPost$Params } from '../fn/account/api-account-change-user-password-post';
import { apiAccountChangeUserPasswordPost$Plain } from '../fn/account/api-account-change-user-password-post-plain';
import { ApiAccountChangeUserPasswordPost$Plain$Params } from '../fn/account/api-account-change-user-password-post-plain';
import { apiAccountGetCurrentUserGet } from '../fn/account/api-account-get-current-user-get';
import { ApiAccountGetCurrentUserGet$Params } from '../fn/account/api-account-get-current-user-get';
import { apiAccountGetCurrentUserGet$Plain } from '../fn/account/api-account-get-current-user-get-plain';
import { ApiAccountGetCurrentUserGet$Plain$Params } from '../fn/account/api-account-get-current-user-get-plain';
import { apiAccountSetUserPasswordPost } from '../fn/account/api-account-set-user-password-post';
import { ApiAccountSetUserPasswordPost$Params } from '../fn/account/api-account-set-user-password-post';
import { apiAccountSetUserPasswordPost$Plain } from '../fn/account/api-account-set-user-password-post-plain';
import { ApiAccountSetUserPasswordPost$Plain$Params } from '../fn/account/api-account-set-user-password-post-plain';
import { apiAccountUserLockPost } from '../fn/account/api-account-user-lock-post';
import { ApiAccountUserLockPost$Params } from '../fn/account/api-account-user-lock-post';
import { apiAccountUserLockPost$Plain } from '../fn/account/api-account-user-lock-post-plain';
import { ApiAccountUserLockPost$Plain$Params } from '../fn/account/api-account-user-lock-post-plain';
import { apiAccountUserUnLockPost } from '../fn/account/api-account-user-un-lock-post';
import { ApiAccountUserUnLockPost$Params } from '../fn/account/api-account-user-un-lock-post';
import { apiAccountUserUnLockPost$Plain } from '../fn/account/api-account-user-un-lock-post-plain';
import { ApiAccountUserUnLockPost$Plain$Params } from '../fn/account/api-account-user-un-lock-post-plain';
import { ChangeEmailCommandResult } from '../models/change-email-command-result';
import { ChangePasswordCommandResult } from '../models/change-password-command-result';
import { GetUserQueryResult } from '../models/get-user-query-result';
import { SetPasswordCommandResult } from '../models/set-password-command-result';
import { UserLockCommandResult } from '../models/user-lock-command-result';
import { UserUnlockCommandResult } from '../models/user-unlock-command-result';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountGetCurrentUserGet()` */
  static readonly ApiAccountGetCurrentUserGetPath = '/api/Account/GetCurrentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetCurrentUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetCurrentUserGet$Plain$Response(params?: ApiAccountGetCurrentUserGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserQueryResult>> {
    return apiAccountGetCurrentUserGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetCurrentUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetCurrentUserGet$Plain(params?: ApiAccountGetCurrentUserGet$Plain$Params, context?: HttpContext): Observable<GetUserQueryResult> {
    return this.apiAccountGetCurrentUserGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserQueryResult>): GetUserQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGetCurrentUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetCurrentUserGet$Response(params?: ApiAccountGetCurrentUserGet$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUserQueryResult>> {
    return apiAccountGetCurrentUserGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGetCurrentUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGetCurrentUserGet(params?: ApiAccountGetCurrentUserGet$Params, context?: HttpContext): Observable<GetUserQueryResult> {
    return this.apiAccountGetCurrentUserGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUserQueryResult>): GetUserQueryResult => r.body)
    );
  }

  /** Path part for operation `apiAccountChangeUserPasswordPost()` */
  static readonly ApiAccountChangeUserPasswordPostPath = '/api/Account/ChangeUserPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangeUserPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeUserPasswordPost$Plain$Response(params?: ApiAccountChangeUserPasswordPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangePasswordCommandResult>> {
    return apiAccountChangeUserPasswordPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangeUserPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeUserPasswordPost$Plain(params?: ApiAccountChangeUserPasswordPost$Plain$Params, context?: HttpContext): Observable<ChangePasswordCommandResult> {
    return this.apiAccountChangeUserPasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangePasswordCommandResult>): ChangePasswordCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangeUserPasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeUserPasswordPost$Response(params?: ApiAccountChangeUserPasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangePasswordCommandResult>> {
    return apiAccountChangeUserPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangeUserPasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeUserPasswordPost(params?: ApiAccountChangeUserPasswordPost$Params, context?: HttpContext): Observable<ChangePasswordCommandResult> {
    return this.apiAccountChangeUserPasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangePasswordCommandResult>): ChangePasswordCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAccountSetUserPasswordPost()` */
  static readonly ApiAccountSetUserPasswordPostPath = '/api/Account/SetUserPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSetUserPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountSetUserPasswordPost$Plain$Response(params?: ApiAccountSetUserPasswordPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SetPasswordCommandResult>> {
    return apiAccountSetUserPasswordPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSetUserPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountSetUserPasswordPost$Plain(params?: ApiAccountSetUserPasswordPost$Plain$Params, context?: HttpContext): Observable<SetPasswordCommandResult> {
    return this.apiAccountSetUserPasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetPasswordCommandResult>): SetPasswordCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSetUserPasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountSetUserPasswordPost$Response(params?: ApiAccountSetUserPasswordPost$Params, context?: HttpContext): Observable<StrictHttpResponse<SetPasswordCommandResult>> {
    return apiAccountSetUserPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSetUserPasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountSetUserPasswordPost(params?: ApiAccountSetUserPasswordPost$Params, context?: HttpContext): Observable<SetPasswordCommandResult> {
    return this.apiAccountSetUserPasswordPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetPasswordCommandResult>): SetPasswordCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAccountChangeEmailPost()` */
  static readonly ApiAccountChangeEmailPostPath = '/api/Account/ChangeEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangeEmailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeEmailPost$Plain$Response(params?: ApiAccountChangeEmailPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeEmailCommandResult>> {
    return apiAccountChangeEmailPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangeEmailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeEmailPost$Plain(params?: ApiAccountChangeEmailPost$Plain$Params, context?: HttpContext): Observable<ChangeEmailCommandResult> {
    return this.apiAccountChangeEmailPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeEmailCommandResult>): ChangeEmailCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountChangeEmailPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeEmailPost$Response(params?: ApiAccountChangeEmailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeEmailCommandResult>> {
    return apiAccountChangeEmailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountChangeEmailPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountChangeEmailPost(params?: ApiAccountChangeEmailPost$Params, context?: HttpContext): Observable<ChangeEmailCommandResult> {
    return this.apiAccountChangeEmailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeEmailCommandResult>): ChangeEmailCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAccountUserLockPost()` */
  static readonly ApiAccountUserLockPostPath = '/api/Account/UserLock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserLockPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserLockPost$Plain$Response(params?: ApiAccountUserLockPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserLockCommandResult>> {
    return apiAccountUserLockPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserLockPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserLockPost$Plain(params?: ApiAccountUserLockPost$Plain$Params, context?: HttpContext): Observable<UserLockCommandResult> {
    return this.apiAccountUserLockPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserLockCommandResult>): UserLockCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserLockPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserLockPost$Response(params?: ApiAccountUserLockPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UserLockCommandResult>> {
    return apiAccountUserLockPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserLockPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserLockPost(params?: ApiAccountUserLockPost$Params, context?: HttpContext): Observable<UserLockCommandResult> {
    return this.apiAccountUserLockPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserLockCommandResult>): UserLockCommandResult => r.body)
    );
  }

  /** Path part for operation `apiAccountUserUnLockPost()` */
  static readonly ApiAccountUserUnLockPostPath = '/api/Account/UserUnLock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserUnLockPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserUnLockPost$Plain$Response(params?: ApiAccountUserUnLockPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUnlockCommandResult>> {
    return apiAccountUserUnLockPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserUnLockPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserUnLockPost$Plain(params?: ApiAccountUserUnLockPost$Plain$Params, context?: HttpContext): Observable<UserUnlockCommandResult> {
    return this.apiAccountUserUnLockPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUnlockCommandResult>): UserUnlockCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountUserUnLockPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserUnLockPost$Response(params?: ApiAccountUserUnLockPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UserUnlockCommandResult>> {
    return apiAccountUserUnLockPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountUserUnLockPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountUserUnLockPost(params?: ApiAccountUserUnLockPost$Params, context?: HttpContext): Observable<UserUnlockCommandResult> {
    return this.apiAccountUserUnLockPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserUnlockCommandResult>): UserUnlockCommandResult => r.body)
    );
  }

}
