/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiUnitCreateUnitPost } from '../fn/unit/api-unit-create-unit-post';
import { ApiUnitCreateUnitPost$Params } from '../fn/unit/api-unit-create-unit-post';
import { apiUnitCreateUnitPost$Plain } from '../fn/unit/api-unit-create-unit-post-plain';
import { ApiUnitCreateUnitPost$Plain$Params } from '../fn/unit/api-unit-create-unit-post-plain';
import { apiUnitDeleteUnitPost } from '../fn/unit/api-unit-delete-unit-post';
import { ApiUnitDeleteUnitPost$Params } from '../fn/unit/api-unit-delete-unit-post';
import { apiUnitDeleteUnitPost$Plain } from '../fn/unit/api-unit-delete-unit-post-plain';
import { ApiUnitDeleteUnitPost$Plain$Params } from '../fn/unit/api-unit-delete-unit-post-plain';
import { apiUnitGetUnitPost } from '../fn/unit/api-unit-get-unit-post';
import { ApiUnitGetUnitPost$Params } from '../fn/unit/api-unit-get-unit-post';
import { apiUnitGetUnitPost$Plain } from '../fn/unit/api-unit-get-unit-post-plain';
import { ApiUnitGetUnitPost$Plain$Params } from '../fn/unit/api-unit-get-unit-post-plain';
import { apiUnitGetUnitsPost } from '../fn/unit/api-unit-get-units-post';
import { ApiUnitGetUnitsPost$Params } from '../fn/unit/api-unit-get-units-post';
import { apiUnitGetUnitsPost$Plain } from '../fn/unit/api-unit-get-units-post-plain';
import { ApiUnitGetUnitsPost$Plain$Params } from '../fn/unit/api-unit-get-units-post-plain';
import { apiUnitUpdateUnitPost } from '../fn/unit/api-unit-update-unit-post';
import { ApiUnitUpdateUnitPost$Params } from '../fn/unit/api-unit-update-unit-post';
import { apiUnitUpdateUnitPost$Plain } from '../fn/unit/api-unit-update-unit-post-plain';
import { ApiUnitUpdateUnitPost$Plain$Params } from '../fn/unit/api-unit-update-unit-post-plain';
import { CreateUnitCommandResult } from '../models/create-unit-command-result';
import { DeleteUnitCommandResult } from '../models/delete-unit-command-result';
import { GetUnitQueryResult } from '../models/get-unit-query-result';
import { GetUnitsQueryResult } from '../models/get-units-query-result';
import { UpdateUnitCommandResult } from '../models/update-unit-command-result';

@Injectable({ providedIn: 'root' })
export class UnitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiUnitGetUnitPost()` */
  static readonly ApiUnitGetUnitPostPath = '/api/Unit/GetUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitGetUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitPost$Plain$Response(params?: ApiUnitGetUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUnitQueryResult>> {
    return apiUnitGetUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitGetUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitPost$Plain(params?: ApiUnitGetUnitPost$Plain$Params, context?: HttpContext): Observable<GetUnitQueryResult> {
    return this.apiUnitGetUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUnitQueryResult>): GetUnitQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitGetUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitPost$Response(params?: ApiUnitGetUnitPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUnitQueryResult>> {
    return apiUnitGetUnitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitGetUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitPost(params?: ApiUnitGetUnitPost$Params, context?: HttpContext): Observable<GetUnitQueryResult> {
    return this.apiUnitGetUnitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUnitQueryResult>): GetUnitQueryResult => r.body)
    );
  }

  /** Path part for operation `apiUnitGetUnitsPost()` */
  static readonly ApiUnitGetUnitsPostPath = '/api/Unit/GetUnits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitGetUnitsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitsPost$Plain$Response(params?: ApiUnitGetUnitsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUnitsQueryResult>> {
    return apiUnitGetUnitsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitGetUnitsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitsPost$Plain(params?: ApiUnitGetUnitsPost$Plain$Params, context?: HttpContext): Observable<GetUnitsQueryResult> {
    return this.apiUnitGetUnitsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUnitsQueryResult>): GetUnitsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitGetUnitsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitsPost$Response(params?: ApiUnitGetUnitsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUnitsQueryResult>> {
    return apiUnitGetUnitsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitGetUnitsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitGetUnitsPost(params?: ApiUnitGetUnitsPost$Params, context?: HttpContext): Observable<GetUnitsQueryResult> {
    return this.apiUnitGetUnitsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUnitsQueryResult>): GetUnitsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiUnitCreateUnitPost()` */
  static readonly ApiUnitCreateUnitPostPath = '/api/Unit/CreateUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitCreateUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitCreateUnitPost$Plain$Response(params?: ApiUnitCreateUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitCommandResult>> {
    return apiUnitCreateUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitCreateUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitCreateUnitPost$Plain(params?: ApiUnitCreateUnitPost$Plain$Params, context?: HttpContext): Observable<CreateUnitCommandResult> {
    return this.apiUnitCreateUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitCommandResult>): CreateUnitCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitCreateUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitCreateUnitPost$Response(params?: ApiUnitCreateUnitPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUnitCommandResult>> {
    return apiUnitCreateUnitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitCreateUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitCreateUnitPost(params?: ApiUnitCreateUnitPost$Params, context?: HttpContext): Observable<CreateUnitCommandResult> {
    return this.apiUnitCreateUnitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUnitCommandResult>): CreateUnitCommandResult => r.body)
    );
  }

  /** Path part for operation `apiUnitUpdateUnitPost()` */
  static readonly ApiUnitUpdateUnitPostPath = '/api/Unit/UpdateUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitUpdateUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitUpdateUnitPost$Plain$Response(params?: ApiUnitUpdateUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateUnitCommandResult>> {
    return apiUnitUpdateUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitUpdateUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitUpdateUnitPost$Plain(params?: ApiUnitUpdateUnitPost$Plain$Params, context?: HttpContext): Observable<UpdateUnitCommandResult> {
    return this.apiUnitUpdateUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUnitCommandResult>): UpdateUnitCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitUpdateUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitUpdateUnitPost$Response(params?: ApiUnitUpdateUnitPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateUnitCommandResult>> {
    return apiUnitUpdateUnitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitUpdateUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitUpdateUnitPost(params?: ApiUnitUpdateUnitPost$Params, context?: HttpContext): Observable<UpdateUnitCommandResult> {
    return this.apiUnitUpdateUnitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUnitCommandResult>): UpdateUnitCommandResult => r.body)
    );
  }

  /** Path part for operation `apiUnitDeleteUnitPost()` */
  static readonly ApiUnitDeleteUnitPostPath = '/api/Unit/DeleteUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitDeleteUnitPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitDeleteUnitPost$Plain$Response(params?: ApiUnitDeleteUnitPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteUnitCommandResult>> {
    return apiUnitDeleteUnitPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitDeleteUnitPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitDeleteUnitPost$Plain(params?: ApiUnitDeleteUnitPost$Plain$Params, context?: HttpContext): Observable<DeleteUnitCommandResult> {
    return this.apiUnitDeleteUnitPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteUnitCommandResult>): DeleteUnitCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUnitDeleteUnitPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitDeleteUnitPost$Response(params?: ApiUnitDeleteUnitPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteUnitCommandResult>> {
    return apiUnitDeleteUnitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUnitDeleteUnitPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUnitDeleteUnitPost(params?: ApiUnitDeleteUnitPost$Params, context?: HttpContext): Observable<DeleteUnitCommandResult> {
    return this.apiUnitDeleteUnitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteUnitCommandResult>): DeleteUnitCommandResult => r.body)
    );
  }

}
